
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { VitalAdminTabService } from '../../tab.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';
import { ManageJsonEditorComponent } from '../manage-json-editor/manage-json-editor.component';

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-organization-attributes',
  templateUrl: './organization-attributes.component.html',
  styleUrls: ['./organization-attributes.component.scss']
})
export class OrganizationAttributesComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  @Input()
  templateData: any;
  gridData: any;
  orgingAttributeData: any;
  AttributeTypes = [];
  filterOrganizations: Observable<any[]>;
  gridheader = [
    "Attribute_Context",
    "Attribute_Context_Id_3",
    "Attribute_Context_Id_2",
    "Attribute_Service_Type",
    "Attribute_Value",
    "Attribute_Description",
    "Attribute_Context_Id",
    "Attribute_ID",
    "Attribute_Level",
    "Status",
    "Sequence_Order",
    "Attribute_Name",
    "Attribute_Type",
    "Created_By",
    "CreatedDate",
    "Modified_By",
    "Modified_Date"
  ];
  showPaginationMainGrid: boolean;
  attributeType = new FormControl();
  tempExport: any;
  orgname = new FormControl();
  gridPage: boolean = false;
  gridWidth: number;
  workBook: any;
  sheetsToSelect: any[];
  orgAttributeForm = this._fb.group({
    frmAttrType: ["", Validators.required],
    frmAttrName: "",
    frmIsActive: true,
    frmSeqOrder: "",
    AttributeContext: "",
    AttributeContextId: '',
    AttributeDescription: '',
    AttributeValue: '',
    AttributeServiceType: '',
    AttributeContextId2: '',
    AttributeContextId3: ''
  })
  configname = new FormControl();
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  sheetHeader: any[];
  gridDisplay: boolean;
  showDelete: boolean;
  orgId: any;
  cardType: any;
  attrtype: any = "";
  addEditScreen: boolean;
  showGrid: boolean = false;
  action: string;
  attrID: any;
  gridwidth: number;
  searchInput: string;
  searchUserInput: string;
  usertoggle: boolean;
  toggle: boolean;
  hideGrid: boolean;
  gridShowDiv: boolean;
  showPagination: boolean;
  searchResult: any[];
  orgName: any;
  organizationid: any;
  selectedItems: any = [];
  checkStatus: boolean;
  showMsg: boolean;
  globalexist: boolean = false;
  hideExportBtn: boolean;
  hideCreateBtn: boolean;
  hideEditBtn: boolean;
  hideCopyBtn: boolean;
  hideUploadBtn: boolean;
  tempAttributeTypes: any[];
  globalAttrTypes: any[];
  globalData: any[];
  globalIndicater: boolean;
  context: string;
  oldObject: { attributetype: any; attributename: any;attributeid:any; isactive: boolean; sequenceorder: any; };
  locations:string="" ;
  showConfirmPopupFlag:any;

  //---------for searchable Dropdown-------------
  filteredOptions: Observable<any[]>;
  filteredContextId: Observable<any[]>;

  filterAttrContext1: Observable<any[]>;
  filteredAttrContext2: Observable<any[]>;
  filteredAttrContext3: Observable<any[]>;
  filteredDesc: Observable<any[]>;
  filteredAttrValue: Observable<any[]>;
  filteredServicesType: Observable<any[]>;
  filterAttrContext: Observable<any[]>;
  //-----------------------------------------------
  attributesName: any;
  attributeFields: any;
  attributeJson: any;
  lookupData: any;

  // Array fro storing dropdown

  attributecontextidArray: any;
  attributecontextid2Arr: any;
  attributeDescArr: any;
  attrValue: any;
  attrServiceType: any;
  attrContext: any
  attributecontextid3Arr: any;

  //------------------------------------
  // checking id or name it wants for configuration
  attrContextIdflag: boolean = false;
  attrContextId2flag: boolean = false;
  attrContextflag: boolean = false;
  attrDescFlag: boolean = false;
  attrValueflag: boolean = false;
  attrServiceflag: boolean = false;
  attrContextid3flag: boolean = false;
  searchAttr: boolean = true;
  deploymentKey: any;
  accountID: any;

  showModal: boolean = true;
  organizationId: any;
  nodataFoundEnable: boolean;
  activityEntity: any;
  auditableColumns: any;
  PopUpmsgs = (locations) => {
    return {
      1: { message: "There are sequence schemes available for the other ''Bill To'' values under the group. Please configure Sequence Scheme for this ''Bill To''" },
      2: { message: "There are Sequence schemes available for the other Locations: "+ locations + " Please configure Sequence Scheme  for this BillTo" },
      3: { message: "''Bill To'' attribute modified, new sequence scheme should be configured for this modified ''Bill To''" }
    }
  }

  constructor(public _snackbar: MatSnackBar, private dialog: MatDialog, private _fb: FormBuilder, private vitalHttpServices: VitalHttpServices, private commonService: CommonService, private ngxService: NgxUiLoaderService, public tabService: VitalAdminTabService, public activityService: ActivityTrackerService,public VitalMenuComponent: VitalMenuComponent,) { }

  ngOnInit(): void {
    this.orgId = this.templateData.secondarykeys.OrganizationId;
    this.cardType = this.templateData.cardtype;
    this.attrtype = "All";
    this.deploymentKey = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.accountID = sessionStorage.getItem('Locationid')
    this.GetButtondetails();
    this.getAttributeTypesDropdown();
    this.getAuditableDetails(this.templateData.menuURL);
    this.activityService.setActivitySession({'entityId': '','entityType': this.templateData.menuURL, 'context':[{'key':'parentMenu','value':this.templateData.menuURL}]})
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
  }
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
  ngOnChanges() {
    this.AddGridData();
  }

  getAttributeTypesDropdown() {
    this.ngxService.start();
    this.searchAttr = true;
    this.showGrid = false;
    this.configname.reset();
    let query = this.vitalHttpServices.GetQuery('getAttributeName');
    let queryVariable = { scope: "OrganizationAttributes" };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, 'configdb').subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.attributesName = Resdata.data.submenuData;
        //this.AttributeTypes = data[0].refconfigvalidvalues;
        this.filteredOptions = this.configname.valueChanges
          .pipe(
            startWith(''),
            map(user => user && typeof user === 'object' ? user.configname || user.name : user),
            map((name: string) => name ? this.filterName(name) : this.attributesName.slice())
          );
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });

  }

  filterName(name: string): any[] {
    return this.attributesName.filter(option =>
      option.refconfigname.toLowerCase().includes(name.toLowerCase()) || option.name.toLowerCase().includes(name.toLowerCase()));
  }


  filterLookupAttrId(name: string): any[] {
    return this.attributecontextidArray.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrConId2(name: string): any[] {
    return this.attributecontextid2Arr.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrConId3(name: string): any[] {
    return this.attributecontextid3Arr.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrContext(name: string): any[] {
    return this.attrContext.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrdesc(name: string): any[] {
    return this.attributeDescArr.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrServ(name: string): any[] {
    return this.attrServiceType.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrVal(name: string): any[] {
    return this.attrValue.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }

  getOrgAttibutes(obj) {
    this.ngxService.start();
    this.attrContextIdflag = false;
    this.attrContextId2flag = false;
    this.attrContextflag = false;
    this.attrDescFlag = false;
    this.attrValueflag = false;
    this.attrServiceflag = false;
    this.attrContextid3flag = false;
    this.attributecontextidArray = []
    this.attributecontextid2Arr = []
    this.attributeDescArr = []
    this.attrValue = []
    this.attrServiceType = []
    this.attrContext = []
    this.attributecontextid3Arr=[]
    this.attributecontextid3Arr
    this.vitalHttpServices.getOrganizationAttributes(obj, this.deploymentKey).subscribe(async result => {
      this.ngxService.stop();
      if (!result.error) {
        try {
          this.templateData.submenuData = JSON.parse(result.Message)
          this.globalAttrTypes = [];
          this.globalData = [];
          for (let i = 0; i < this.templateData.submenuData.length; i++) {
            if (this.templateData.submenuData[i]['Attribute_Level'] == 'Default') {
              this.globalexist = true;
              this.globalAttrTypes.push(this.templateData.submenuData[i]['Attribute_Type']);
              this.globalData.push(this.templateData.submenuData[i]);
            }
          }
          this.nodataFoundEnable = false;
          if (this.templateData.submenuData[0].Attribute_ID == "N/A") {
            this.nodataFoundEnable = true;
          }
          if (this.templateData.submenuData[0].validvalues) {
            this.attributeJson = JSON.parse(this.templateData.submenuData[0].validvalues);
            this.attributeFields = this.attributeJson.AttributeFields.filter(va => va.status == 'true')
            let lookUpField = this.attributeFields.filter(va => va.datatype == 'lookup')
            for (let i = 0; i < lookUpField.length; i++) {
              if (lookUpField[i].datasource[0].procedure) {
                this.ngxService.start();
                this.lookupData = await this.vitalHttpServices.getLookUpData(lookUpField[i].datasource[0].procedure, this.orgId, '', this.accountID, this.deploymentKey).toPromise()
                // this.vitalHttpServices.getLookUpData(lookUpField[i].datasource[0].procedure, this.orgId, 'dermatology').subscribe(data => {
                this.ngxService.stop();
                if (this.lookupData && this.lookupData.length > 0) {
                  switch (lookUpField[i].columnname.toLowerCase()) {
                    case "attributecontextid":
                      this.attrContextIdflag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attributecontextidArray = this.lookupData;
                      this.filterAttrContext1 = this.orgAttributeForm.controls.AttributeContextId.valueChanges
                        .pipe(
                          startWith(''),
                          map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrId(name) : this.attributecontextidArray.slice())
                        );
                      break
                    case 'attributecontextid2':
                      this.attrContextId2flag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attributecontextid2Arr = this.lookupData;
                      this.filteredAttrContext2 = this.orgAttributeForm.controls.AttributeContextId2.valueChanges
                        .pipe(
                          startWith(''),
                          map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrConId2(name) : this.attributecontextid2Arr.slice())
                        );
                      break

                    // ----------------
                    case 'attributecontext':
                      this.attrContextflag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attrContext = this.lookupData;
                      this.filterAttrContext = this.orgAttributeForm.controls.AttributeContext.valueChanges
                        .pipe(
                          startWith(''),
                          map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrContext(name) : this.attrContext.slice())
                        );
                      break
                    case 'attributedescription':
                      this.attrDescFlag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attributeDescArr = this.lookupData;
                      this.filteredDesc = this.orgAttributeForm.controls.AttributeDescription.valueChanges
                        .pipe(
                          startWith(''),
                          map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrdesc(name) : this.attributeDescArr.slice())
                        );
                      break
                    case 'attributevalue':
                      this.attrValueflag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attrValue = this.lookupData;
                      this.filteredAttrValue = this.orgAttributeForm.controls.AttributeValue.valueChanges
                        .pipe(
                          startWith(''),
                          map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrVal(name) : this.attrValue.slice())
                        );
                      break
                    case 'attributeservicetype':
                      this.attrServiceflag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attrServiceType = this.lookupData;
                      this.filteredServicesType = this.orgAttributeForm.controls.AttributeServiceType.valueChanges
                        .pipe(
                          startWith(''),
                          map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrServ(name) : this.attrServiceType.slice())
                        );
                      break
                    case 'attributecontextid3':
                      this.attrContextid3flag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attributecontextid3Arr = this.lookupData;
                      this.filteredAttrContext3 = this.orgAttributeForm.controls.AttributeContextId3.valueChanges
                        .pipe(
                          startWith(''),
                          map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrConId3(name) : this.attributecontextid3Arr.slice())
                        );
                      break
                  }
                }
              }
            }
          }
          else {
            this.attributeFields = [];
            this.attributeJson.AttributeFields = [];
          }
          this.globalAttrTypes = [...new Set(this.globalAttrTypes)] // to get the distinct attribute types
          this.AddGridData();
        }
        catch {
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        }

      }
    }, error => {
      this.ngxService.stop();
      console.error(error)
    })
  }

  AddGridData() {
    let gridarray = []
    let primary = {}
    this.searchAttr = true;
    this.showGrid = true;
    this.addEditScreen = false;
    if (this.templateData.submenuData) {
      //this.tempExport = this.templateData.submenuData;
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
        if (this.templateData.submenuData.find(r => (r.Attribute_Level == 'Default'))) {
          this.showModal = true;
        } else {
          this.showModal = false;
        }
        gridarray.sort((a, b) => a.Sequence_Order < b.Sequence_Order ? 1 : -1).sort((a, b) => a.Attribute_Type > b.Attribute_Type ? 1 : -1)
      }
      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
    }
  }

  getOrgAttributeOfDropdown(data) {
    this.tempAttributeTypes = [];
    if (data != 'All') {
      this.tempAttributeTypes.push(data)
    }
    else {
      for (let i = 0; i < this.AttributeTypes.length; i++) {
        this.tempAttributeTypes.push(this.AttributeTypes[i].Item);
      }
    }
    this.attrtype = data;
    let obj = {
      "organizationid": this.orgId,
      "attributetype": this.tempAttributeTypes,
      "tablename": "OrganizationAttributes"
    }
    this.getOrgAttibutes(obj);
  }
  showEditIconInGrid(row) {
    let level = row.dataItem.Attribute_Level;
    if (level == 'Default')
      return false;
    else
      return true;
  }

  initGrid(flexgrid) {
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  //#region Export grid data as excel

  exportOrgAttributesData(flex) {
    let excel = [];
    let modifiedExcel: any = [];
    const view = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate()
    let rows = flex.rows
    rows.find(e => {
      delete e._data['Attribute_Context'];
      delete e._data['Attribute_Context_Id'];
      delete e._data['Attribute_Description'];
      delete e._data['Attribute_Value'];
      delete e._data['Attribute_Service_Type'];
      delete e._data['Attribute_Context_Id_2'];
      delete e._data['Attribute_Context_Id_3'];
      // delete e._data['Attribute_Level'];
      let primary = {}
      for (let [key, value] of Object.entries(e._data)) {
        if (key == 'Status') {
          value = value == 'Active' ? true : false;
          primary['Status'] = value;
        }
        if (!value) {
          value = 'Not Specified';
        }
        primary[key] = value;
      }
      excel.push(primary);
    });
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Attributes_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Attributes');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate()
  }


  refreshGrid() {
    this.ngxService.start();
    this.showGrid = true;
    this.searchAttr = true;
    this.addEditScreen = false;
    this.globalIndicater = false;
    this.orgAttributeForm.reset();
    this.getOrgAttributeOfDropdown(this.attrtype)
    this.attributeType.setValue(this.attrtype);
    this.searchInput = '';
    this.searchUserInput = '';
    this.checkStatus = false;
    this.ngxService.stop();
  }

  removeGrid() {
    this.gridWidth = 0;
    this.showDelete = false;
  }
  redirectToSequenceSchemeOnSave(){
    this.VitalMenuComponent.changeComponent('Sequence Scheme');
  }
  //#region
  addOrgAttributes(dataArray, bulkUpload: boolean = false, context?: string, opt?: boolean) {
    // if (!this.globalexist && this.context != 'copy') {
    //   for (let i = 0; i < dataArray.length; i++) {
    //     if (this.templateData.submenuData.some(va => va.Attribute_Name == dataArray[0].attributename && va.Attribute_Type == dataArray[0].attributetype && va.Attribute_ID != dataArray[0].attributeid)) {
    //       this._snackbar.open("Configuration is already exists", 'close');
    //       this.ngxService.stop();
    //       return;
    //     }
    //   }
    // }
    this.ngxService.start();
    if(this.attrtype?.toString().toLowerCase() == 'labcorpsendoutconfig')
    {
      this.auditableColumns["attributevalue"] = "Attribute Value";
    } else {
      /*Date: 07-11-2024-----------------------------------------
        AttributeValue column is not configured for any attribute, 
        so deleting it for those.-------------------------------*/
      delete this.auditableColumns["attributevalue"];
    }
    this.checkStatus = false;
    this.vitalHttpServices.addOrganizationAttributes(dataArray, this.deploymentKey).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (opt) {
          this.refreshGrid();
          this.showModal = false;
          dataArray.filter((e: any) => {
            result.filter(r => {
              if (r.AttributeName.toString() === e.attributename.toString()) {
                e.status = r.Status
                e.notes = r.Notes;
              }
            });
          });
          this.commonService.auditDetails('','',[],dataArray, 'Migrate', this.templateData, this.auditableColumns);
          return;
        }
        if (result.length > 0) {
          this.showDelete = false;
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            this._snackbar.open('Attribute with the same name already exists.', 'Close');
          }
          else {
            this._snackbar.open('Group attribute added successfully', 'Close');
            dataArray[0]['isactive'] = this.commonService.getStatus(dataArray[0]['isactive']);
            dataArray[0]['sequenceorder'] = result[0]['SequenceOrder'];
            if(result[0]['SeqAlert']==1){
              this.showConfirmPopupFlag = result[0]['SeqAlert'];
            }else if(result[0]['SeqAlert']==2){
              this.showConfirmPopupFlag = result[0]['SeqAlert'];
              this.locations=result[0]['Locations'];
            }
            this.commonService.auditDetails('attributeid','attributename',result, dataArray, 'Create',this.templateData,this.auditableColumns);
            if (context) {
              this.globalIndicater = false;
              this.getOrgAttributeOfDropdown(this.attrtype)
              this.attributeType.setValue(this.attrtype);
            }
            else {
              this.orgAttributeForm.reset();
              this.globalexist = false;
              let attrtype = this.attrtype == 'All' ? '' : this.attrtype
              this.orgAttributeForm.reset();
              this.orgAttributeForm.patchValue({
                frmAttrType: attrtype,
                frmAttrName: "",
                frmIsActive: true,
                frmSeqOrder: "",
                AttributeContext: "",
                AttributeContextId: '',
                AttributeDescription: '',
                AttributeValue: '',
                AttributeServiceType: '',
                AttributeContextId2: '',
                AttributeContextId3: ''
              })
              this.globalIndicater = false;
              Object.keys(this.orgAttributeForm.controls).forEach(key => {
                this.orgAttributeForm.controls[key].setErrors(null)
              });
            }
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('Group Attribute addition failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //#endregion
  //#region  for patching to the form to update
  updateOrgAttributes(row) {
    if (this.hideEditBtn) {
      this._snackbar.open('User is not Authorized', 'Close');
      return;
    }
    let patchContextId = this.attrContextIdflag && this.attributecontextidArray && this.attributecontextidArray.length > 0 ? this.attributecontextidArray.filter(va => va.id == row.dataItem.Attribute_Context_Id) : null
    let patchAttrContext = this.attrContextflag && this.attrContext && this.attrContext.length > 0 ? this.attrContext.filter(va => va.id == row.dataItem.Attribute_Context) : null
    let patchDescription = this.attrDescFlag && this.attributeDescArr && this.attributeDescArr.length > 0 ? this.attributeDescArr.filter(va => va.id == row.dataItem.Attribute_Description) : null
    let patchAttrValue = this.attrValueflag && this.attrValue && this.attrValue.length > 0 ? this.attrValue.filter(va => va.id == row.dataItem.Attribute_Value) : null
    let patchServiceType = this.attrServiceflag && this.attrServiceType && this.attrServiceType.length > 0 ? this.attrServiceType.filter(va => va.id == row.dataItem.Attribute_Service_Type) : null
    let patchAttrContextId2 = this.attrContextId2flag && this.attributecontextid2Arr && this.attributecontextid2Arr.length > 0 ? this.attributecontextid2Arr.filter(va => va.id == row.dataItem.Attribute_Context_Id_2) : null
    let patchAttrContextId3 = this.attrContextid3flag && this.attributecontextid3Arr && this.attributecontextid3Arr.length > 0 ? this.attributecontextid3Arr.filter(va => va.id == row.dataItem.Attribute_Context_Id_3) : null

    this.ngxService.start();
    this.addEditScreen = true;
    this.showGrid = false;
    this.searchAttr = false;
    this.action = 'Edit ' + row.dataItem.Attribute_Type;
    this.attrID = row.dataItem.Attribute_ID;
    let tempStatus = row.dataItem.Status == 'Inactive' ? false : true
    row.dataItem.Attribute_Type = row.dataItem.Attribute_Type;
    this.orgAttributeForm.patchValue({
      frmAttrType: row.dataItem.Attribute_Type,
      frmAttrName: row.dataItem.Attribute_Name,
      frmIsActive: tempStatus,
      frmSeqOrder: row.dataItem.Sequence_Order,
      AttributeContext: patchAttrContext && patchAttrContext.length ? patchAttrContext[0].name + ' (' + patchAttrContext[0].id + ')' : row.dataItem.Attribute_Context,
      AttributeContextId: patchContextId && patchContextId.length ? patchContextId[0].name + ' (' + patchContextId[0].id + ')' : row.dataItem.Attribute_Context_Id,
      AttributeDescription: patchDescription && patchDescription.length ? patchDescription[0].name + ' (' + patchDescription[0].id + ')' : row.dataItem.Attribute_Description,
      AttributeValue: patchAttrValue && patchAttrValue.length ? patchAttrValue[0].name + ' (' + patchAttrValue[0].id + ')' : row.dataItem.Attribute_Value,
      AttributeServiceType: patchServiceType && patchServiceType.length ? patchServiceType[0].name + ' (' + patchServiceType[0].id + ')' : row.dataItem.Attribute_Service_Type,
      AttributeContextId2: patchAttrContextId2 && patchAttrContextId2.length ? patchAttrContextId2[0].name + ' (' + patchAttrContextId2[0].id + ')' : row.dataItem.Attribute_Context_Id_2,
      AttributeContextId3: patchAttrContextId3 && patchAttrContextId3.length ? patchAttrContextId3[0].name + ' (' + patchAttrContextId3[0].id + ')' : row.dataItem.Attribute_Context_Id_3
    })
    this.oldObject = {
      "attributetype": row.dataItem.Attribute_Type,
      "attributename": row.dataItem.Attribute_Name,
      "attributeid": row.dataItem.Attribute_ID,
      "isactive": row.dataItem.Status == 'Active' ? true : false,
      "sequenceorder": row.dataItem.Sequence_Order
    }
    if(this.attrtype?.toString().toLowerCase() == 'labcorpsendoutconfig')
    {
      this.auditableColumns["attributevalue"] = "Attribute Value";
      this.oldObject["attributevalue"] = row.dataItem.Attribute_Value ?? null;
    } else {
      /*Date: 07-11-2024-----------------------------------------
        AttributeValue column is not configured for any attribute, 
        so deleting it for those.-------------------------------*/
      delete this.auditableColumns["attributevalue"];
    }
    this.activityEntity.entityId = this.attrID;
    this.activityService.setActivitySession(this.activityEntity);
    this.ngxService.stop();
  }
  //#endregion

  //#region for indivisual add
  createOrgAttribute() {
    if (this.hideCreateBtn) {
      this._snackbar.open('User is not Authorized', 'Close');
      return;
    }
    this.searchAttr = false;
    this.addEditScreen = true; // flag for enabling Add and edit screen
    this.showGrid = false;
    this.showModal = false;
    let attrtype = this.attrtype == 'All' ? '' : this.attrtype
    this.action = 'Create Group Attribute'
    this.orgAttributeForm.reset();
    this.orgAttributeForm.patchValue({
      frmAttrType: attrtype,
      frmAttrName: '',
      frmIsActive: true,
      frmSeqOrder: '',
      AttributeContext: "",
      AttributeContextId: '',
      AttributeDescription: '',
      AttributeValue: '',
      AttributeServiceType: '',
      AttributeContextId2: '',
      AttributeContextId3: ''
    })

  }
  //#endregion

  onChangeAttrType(value) {
    if (value.source._selected) {
      for (let i = 0; i < this.globalData.length; i++)
        if (value.source.value == this.globalData[i]['Attribute_Type']) {
          this.globalIndicater = true;
          return
        }
        else {
          this.globalIndicater = false;
        }
    }
  }

  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  takingIdOrName(data) {
    const regExp = /\(([^)]+)\)/;

    const processAttribute = (attr, flag, lookup) => {
        if (flag) {
            const match = attr?.match(regExp);
            return match ? match[1] : attr;
        }
        return this.checkLookUp(lookup) ? attr : attr.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, "");
    };

    data.AttributeContextId = processAttribute(data.AttributeContextId, this.attrContextIdflag, "AttributeContextId");
    data.AttributeDescription = processAttribute(data.AttributeDescription, this.attrDescFlag, "AttributeDescription");
    data.AttributeValue = processAttribute(data.AttributeValue, this.attrValueflag, "AttributeValue");
    data.AttributeServiceType = processAttribute(data.AttributeServiceType, this.attrServiceflag, "AttributeServiceType");
    data.AttributeContextId2 = processAttribute(data.AttributeContextId2, this.attrContextId2flag, "AttributeContextId2");
    data.AttributeContextId3 = processAttribute(data.AttributeContextId3, this.attrContextid3flag, "AttributeContextId3");
    data.AttributeContext = processAttribute(data.AttributeContext, this.attrContextflag, "AttributeContext");
}
  considerValueBeforeBrackets(value){
    let arr = [];
    let index = -1;
    if(value[value.length - 1] == ')'){
    for(let i = value.length - 1;i>=0;i--){
      if(value[i]==')'){
        arr.push(value[i])
      }
      else if (value[i]=='('){
        arr.pop();
      }
      if(arr.length == 0){
        index =  i;
        break;
      }
      }
    }
    index = (index == -1) ? value.length : index; 
    return value.substring(0,index - 1).trim();
  }

  checkLookUp(colName) {
    this.attributeFields = this.attributeJson.AttributeFields.filter(va => va.status == 'true');
    let matchingField = this.attributeFields.find(field => field.columnname.toLowerCase() === colName.toLowerCase());
    if (matchingField) {
      if (matchingField.datatype == 'freetext') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

  }
  saveOrgAttributes(action: string, data: any) {
    this.attributeType.setValue('All');
    var regExp = /\(([^)]+)\)/;
    if (data.frmAttrType.trim() == '') {
      this._snackbar.open("Please enter the required values", 'Close')
      return;
    }
    if (this.attributeJson && this.attributeJson.AttributeFields.length > 0) { // checking for json exists
      this.takingIdOrName(data);
    }

    //checking whether user selects proper value from dropdown

    let validDesc = false, validService = false, validContext = false, validAttrValue = false, validContextid = false, validContextid2 = false, validContextid3 = false
    if (!this.attrDescFlag && data.AttributeDescription && this.attrContext?.length > 0 && !this.attrContext.find(va => va.name == data.AttributeDescription)) {
      validDesc = true;
    }
    else if (this.attrDescFlag && data.AttributeDescription && this.attrContext?.length > 0 && !this.attrContext.find(va => va.id == data.AttributeDescription)) {
      validDesc = true
    }
    if (!this.attrServiceflag && data.AttributeServiceType && this.attrServiceType?.length > 0 && !this.attrServiceType.find(va => va.name == data.AttributeServiceType)) {
      validService = true
    }
    else if (this.attrServiceflag && data.AttributeServiceType && this.attrServiceType?.length > 0 && !this.attrServiceType.find(va => va.id == data.AttributeServiceType)) {
      validService = true
    }
    if (!this.attrContextflag && data.AttributeContext.match(regExp) && data.AttributeContext?.length > 0 && this.attrContext && !this.attrContext.find(va => va.name == data.AttributeContext)) {
      validContext = true
    }
    else if (this.attrContextflag  && data.AttributeContext && this.attrContext?.length > 0 && !this.attrContext.find(va => va.id == data.AttributeContext)) {
      validContext = true
    }
    if (!this.attrValueflag && data.AttributeValue && this.attrValue?.length > 0 && !this.attrValue.find(va => va.name == data.AttributeValue)) {
      validAttrValue = true
    }
    else if (this.attrValueflag && data.AttributeValue && this.attrValue?.length > 0 && !this.attrValue.find(va => va.id == data.AttributeValue)) {
      validAttrValue = true
    }
    if (!this.attrContextIdflag && data.AttributeContextId && this.attributecontextidArray?.length > 0 && !this.attributecontextidArray.find(va => va.name == data.AttributeContextId)) {
      validContextid = true
    }
    else if (this.attrContextIdflag && data.AttributeContextId && this.attributecontextidArray?.length > 0 && !this.attributecontextidArray.find(va => va.id == data.AttributeContextId)) {
      validContextid = true
    }
    if (!this.attrContextId2flag && data.AttributeContextId2 && this.attributecontextid2Arr?.length > 0 && !this.attributecontextid2Arr.find(va => va.name == data.AttributeContextId2)) {
      validContextid2 = true
    }
    else if (this.attrContextId2flag && data.AttributeContextId2 && this.attributecontextid2Arr?.length > 0 && !this.attributecontextid2Arr.find(va => va.id == data.AttributeContextId2)) {
      validContextid2 = true
    }
    if (!this.attrContextid3flag && data.AttributeContextId3 && this.attributecontextid3Arr?.length > 0 && !this.attributecontextid3Arr.find(va => va.name == data.AttributeContextId3)) {
      validContextid3 = true
    }
    else if (this.attrContextid3flag && data.AttributeContextId3 && this.attributecontextid3Arr?.length > 0 && !this.attributecontextid3Arr.find(va => va.id == data.AttributeContextId3)) {
      validContextid3 = true
    }
    if (validDesc || validService || validContext || validAttrValue || validContextid || validContextid2 || validContextid3) {
      if (validDesc) {
        this.orgAttributeForm.controls["AttributeDescription"].setErrors({ 'incorrect': true })
      }
      if (validService) {
        this.orgAttributeForm.controls["AttributeServiceType"].setErrors({ 'incorrect': true })
      }
      if (validContext) {
        this.orgAttributeForm.controls["AttributeContext"].setErrors({ 'incorrect': true })
      }
      if (validAttrValue) {
        this.orgAttributeForm.controls["AttributeValue"].setErrors({ 'incorrect': true })
      }
      if (validContextid) {
        this.orgAttributeForm.controls["AttributeContextId"].setErrors({ 'incorrect': true })
      }
      if (validContextid2) {
        this.orgAttributeForm.controls["AttributeContextId2"].setErrors({ 'incorrect': true })
      }
      if (validContextid3) {
        this.orgAttributeForm.controls["AttributeContextId3"].setErrors({ 'incorrect': true })
      }
      this._snackbar.open("Please select valid data", "Close")
      return
    }

    let dataJson = [];
    let globalJson = {};
    let tempdataJson = {};
    if (this.globalexist == true && !this.action.match(/edit/i)) {
      for (let i = 0; i < this.globalData.length; i++) {
        if (data.frmAttrType == this.globalData[i].Attribute_Type) {
          globalJson = {
            "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
            "attributetype": this.globalData[i].Attribute_Type,
            "attributename": this.globalData[i].Attribute_Name,
            "attributecontext": this.globalData[i].Attribute_Context,
            "organizationid": sessionStorage.getItem("org_id"),
            "isactive": this.globalData[i].Active = 'Active' ? true : false,
            "sequenceorder": this.globalData[i].Sequence_Order,
            "attributeid": null,
            "attributecontextid": this.globalData[i].Attribute_Context_Id ? this.globalData[i].Attribute_Context_Id : null,
            "attributedescription": this.globalData[i].Attribute_Description ? this.globalData[i].Attribute_Description : null,
            "attributevalue": this.globalData[i].Attribute_Value ? this.globalData[i].Attribute_Value : null,
            "attributeservicetype": this.globalData[i].Attribute_Service_Type ? this.globalData[i].Attribute_Service_Type : null,
            "attributecontextid2": this.globalData[i].Attribute_Context_Id_2 ? this.globalData[i].Attribute_Context_Id_2 : null,
            "attributecontextid3": this.globalData[i].Attribute_Context_Id_3 ? this.globalData[i].Attribute_Context_Id_3 : null,
            "accountid":this.accountID
          }
          dataJson.push(globalJson)
        }
      }
    }
    tempdataJson = {
      "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
      "attributetype": data.frmAttrType,
      "attributename": data.frmAttrName,
      "attributecontext": data.AttributeContext,
      "organizationid": sessionStorage.getItem("org_id"),
      "isactive": data.frmIsActive,
      "sequenceorder": data.frmSeqOrder == null ? "" : data.frmSeqOrder,
      "attributeid": this.attrID ? this.attrID : null,
      "attributecontextid": data.AttributeContextId ? data.AttributeContextId : null,
      "attributedescription": data.AttributeDescription ? data.AttributeDescription : null,
      "attributevalue": data.AttributeValue ? data.AttributeValue : null,
      "attributeservicetype": data.AttributeServiceType ? data.AttributeServiceType : null,
      "attributecontextid2": data.AttributeContextId2 ? data.AttributeContextId2 : null,
      "attributecontextid3": data.AttributeContextId3 ? data.AttributeContextId3 : null,
      "accountid":this.accountID  
    }
    dataJson.push(tempdataJson)
    if (this.action.match(/edit/i)) {
      this.editOrgAttributes(dataJson);
    }
    else {
      let context = action != 'new' ? "All" : null
      // this.globalexist=action!='new' ? false : null
      this.addOrgAttributes(dataJson, false, context);
    }
  }
  editOrgAttributes(data) {
    this.ngxService.start("edit-data");
    // if (this.templateData.submenuData.some(va => va.Attribute_Name == data[0].attributename && va.Attribute_Type == data[0].attributetype && va.Attribute_ID != data[0].attributeid)) {
    //   this._snackbar.open("Attribute with the same name already exists.", 'close');
    //   this.ngxService.stop();
    //   return;
    // }

    this.vitalHttpServices.updateOrganizationAttributes(data, this.deploymentKey).toPromise().then(response => {
      // this.ngxService.stop("edit-data");
      if (response[0].Notes.toLowerCase() == "updated successfully") {
        data[0]['isactive'] = this.commonService.getStatus(data[0]['isactive']);
        data[0]['sequenceorder'] = response[0]['SequenceOrder'];
        if(response[0]['SeqAlert']==1){
          this.showConfirmPopupFlag = response[0]['SeqAlert'];
        }else if(response[0]['SeqAlert']==2){
          this.showConfirmPopupFlag = response[0]['SeqAlert'];
          this.locations=response[0]['Locations'];
        }else if(response[0]['SeqAlert']==3){
          this.showConfirmPopupFlag = response[0]['SeqAlert'];
        }
        this.oldObject['isactive'] = <any>this.commonService.getStatus(this.oldObject['isactive']);
        data[0].attributevalue = data[0].attributevalue? data[0].attributevalue : '';
        this.commonService.auditDetails('attributeid','attributename',[this.oldObject], data, 'Edit',this.templateData,this.auditableColumns);
        // this.getGrossingAttribute("All");
        // this.commonService.createActivityTracker('Edited', data[0].attributeid, 'Attributes', 'Audit', data[0], this.oldObject);
        this.orgAttributeForm.reset();
        this.getOrgAttributeOfDropdown(this.attrtype)
        this.attributeType.setValue(this.attrtype);
        this.setMesDelay("Group attribute updated successfully");
      }
      else {
        this.setMesDelay("Group attribute update failed!")
      }
      this.ngxService.stop("edit-data");
    }
    ).catch(error => this._snackbar.open("An error occurred while processing your request", "Failed"))
    this.ngxService.stop("edit-data");
  }

  setMesDelay(message){
    setTimeout(() => {
      this._snackbar.open(message, "Close");
    },1000)
  }

  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
    else if (fieldName == 'sequence') {
      if (!fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }
  }
  fnToggleFilter() {
    this.configname.reset();
    this.showGrid = false;
  }

  getGLDataPopup() {
    if (this.hideCreateBtn) {
      this._snackbar.open("User is not Authorized", 'close');
      return;
    }
    this.showModal = true;
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '400px',
      data: { header: this.attrtype, message: "Are you sure to copy the Default to Group level? \n Cannot rollback once it is done.", alert: "", continue: "Yes", cancel: "No" }
    });
    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          let dataJson = [];
          let globalJson = {};
          for (let i = 0; i < this.globalData.length; i++) {
            //let Attrtype = this.globalData[i].Attribute_Type == 'Dimensions' ? 'Dimension1' : this.globalData[i].Attribute_Type
            if (this.attrtype == this.globalData[i].Attribute_Type) {
              // let temp = this.templateData.submenuData.filter(va =>va.Attribute_Level.toLowerCase() != 'group' && (va.Attribute_Name != this.globalData[i].Attribute_Name && va.Attribute_Type != this.globalData[i].Attribute_Type ));
              //   if (temp) {
              globalJson = {
                "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                "attributetype": this.globalData[i].Attribute_Type,
                "attributename": this.globalData[i].Attribute_Name,
                "attributecontext": this.globalData[i].Attribute_Context,
                "organizationid": sessionStorage.getItem("org_id"),
                "isactive": this.globalData[i].Active = 'Active' ? true : false,
                "sequenceorder": this.globalData[i].Sequence_Order,
                "attributeid": null,
                "attributecontextid": this.globalData[i].Attribute_Context_Id ? this.globalData[i].Attribute_Context_Id : null,
                "attributedescription": this.globalData[i].Attribute_Description ? this.globalData[i].Attribute_Description : null,
                "attributevalue": this.globalData[i].Attribute_Value ? this.globalData[i].Attribute_Value : null,
                "attributeservicetype": this.globalData[i].Attribute_Service_Type ? this.globalData[i].Attribute_Service_Type : null,
                "attributecontextid2": this.globalData[i].Attribute_Context_Id_2 ? this.globalData[i].Attribute_Context_Id_2 : null,
                "attributecontextid3": this.globalData[i].Attribute_Context_Id_3 ? this.globalData[i].Attribute_Context_Id_3 : null
              }
              dataJson.push(globalJson)
              //}
            }
          }
          this.addOrgAttributes(dataJson, false, null, true);
        }
      })

  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location}).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }

  // clear the text value when it empty
  onInputChange() {
    if (this.configname.value.trim() === '') {
      this.fnToggleFilter();
    }
  }

  editJson(str) {
    let dialogRef = this.dialog.open(ManageJsonEditorComponent, {
      disableClose: true,
      width: '50vw',
      height: 'auto',
      panelClass: 'admin-custom-popup',
      data: { header: "JSON Editor", message: "", jsonText: this.orgAttributeForm.value[str], continue: "Done", cancel: "Cancel" }
    })
    return dialogRef.afterClosed().toPromise().then(res => {
      if (res && res.result == 'Success') {
        this.orgAttributeForm.patchValue({ [str]: res.jsonText })
      }
    })
  }
}
