import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { DiagnosisTemplatesComponent } from './../diagnosis-templates/diagnosis-templates.component';
import { VitalAdminTabService } from '../../tab.service';
import { DiagnosisTemplate } from 'src/app/model/diagnosis-templates.model';
import { OrgDiagTemplateICDCode } from 'src/app/model/orgdiagtemplateicdcodes.model';
import cloneDeep from 'lodash';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import * as wjcGrid from '@grapecity/wijmo.grid';



class Messages {
  provValidData: string = "Please provide valid data!";
  invalidCaseType: string = "Please provide valid Casetype!";
  invalidUserID: string = "Please provide valid User ID";
  duplicateExists: string = "Duplicate record found in the excel!";
  invalidMappingID: string = "Invalid Mapping ID";
  mandatoryFieldMissing: string = "Mandatory field(s) is missing!";
  // invalidDxData : string = "Invalid data provided!";
  // corresDataInvalid : string = "Corresponding Template is Invalid";
}

@Component({
  selector: 'app-bulk-upload-diagnosis-template',
  templateUrl: './bulk-upload-diagnosis-template.component.html',
  styleUrls: ['./bulk-upload-diagnosis-template.component.scss'],
  providers: [Messages]
})
export class BulkUploadDiagnosisTemplateComponent implements OnInit {

  gridDisplay: boolean = false;
  gridEmpty: boolean = false;
  excelDataArray = [];
  gridArray = [];
  gridWidth: number = 0;
  gridData: wjcCore.CollectionView<any>;
  stainGridData: wjcCore.CollectionView<any>;
  selectedrowData: any = {};
  sheetHeader: string[];
  SubMenuCardModel: SubMenuCardModel;
  isProperfile: boolean;
  invalidColumns: string = "";
  showInvalidColumns: boolean = false;
  highlightNotes = true;
  showDelete: boolean = true;
  //uploadClicked: boolean = true;
  addEditScreen: boolean = false;
  gridPage: boolean = false;

  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('grid', { static: false }) grid: ElementRef;
  @Input()
  public inData: any;
  @Input()
  public templateData: any;
  @Input()
  public hideupload: boolean = true;
  @Input()
  public userRoles: any;
  postDownload: boolean = false;
  postUpload: boolean = false;
  noDataFound: boolean = false;
  showPaginationMainGrid: boolean = false;
  gridheader: any = [];
  sheetsToSelect: any;
  selectedSheet: any;
  worksheet: any;
  workBook: any;
  length: number;
  panelCtrl: FormControl;
  panelDropdown = [];
  action: string = "";
  currentData: any = {};
  uploadBtn: boolean = true;
  createBtn: boolean = true;
  editBtn: boolean = true;
  exportBtn: boolean = true;
  readonlyTestSequence: boolean = false;
  disableApprove: boolean = false;
  bulkUpload: boolean = false;
  invalidCasetypeFlag: boolean = false;
  repeatedNames: string;
  caseTypeArray: any;
  id: any;
  multiSheetHeaders: Array<object> = [];
  contextArray : Array<string> = ['users','physician','pathologist','userid'];
  userCard: Boolean = (this.contextArray.includes(sessionStorage.getItem('search_context').toLowerCase()))
  uploadGrid: CollectionView;
  updateGrid: Boolean = false;
  uploadHeaders: any;
  dbName: string = sessionStorage.getItem('deploymentKey');
  multiSheetUpload: boolean;
  multiSheetData: Array<object>;
  selectedIndex: number = 0;
  activityEntity:any;
  expectedHeaders: any = [];
  uploadedHeaders: any = [];
  UpdateColumnMisMatch: boolean = false;
  missingColumn: any = [];
  bulkUpdateFile: boolean = false;


  getUploadColumns() {
    let dxDisplayColumns = ["Mapping ID", "Diagnosis Group Name", "Site ID", "Case Type", "Diagnosis", "Diagnosis Code", "Diagnostic Summary", "Microscopic Notes", "Search Code", "Display Order", "Severity Order", "Report To Registry", "Schematic Display", "Display Color", "Display Pattern", "Display Shape", "RPT Cancer Registry Settings", "Reference", "Reference Summary", "Comment Code", "Comment Summary", "Is Benign", "Abnormal", "Site Group Order", "Site Description", "Default Diagnosis Template ID", "Reference Diagnosis Template ID", "User ID", "Specimen Comments", "Allow Cyto Tech To Sign-Out", "Allow Cyto Tech Supervisor To Sign-Out", "Site Not Survived Template", "Site Not Submitted Template", "Diagnosis Tags", "Diagnostics Summary HTML", "Microscopic Notes HTML", "Comment Summary HTML", "Notes", "Status"];
    let dxMappingColumns = ["MappingId", "DiagnosisGroupName", "SiteId", "CaseType", "Diagnosis", "DiagnosisCode", "DiagnosisSummary", "MicroscopicNotes", "SearchCode", "DisplayOrder", "SeverityOrder", "ReportToRegistry", "SchematicDisplay", "DisplayColor", "DisplayPattern", "DisplayShape", "RptCancerRegistrySettings", "Reference", "ReferenceSummary", "CommentCode", "CommentSummary", "IsBenign", "Abnormal", "SiteGroupOrder", "SiteDescription", "DefaultOrgDiagTemplateID", "ReforgDiagTemplateId", "UserLevelTemplateUserId", "SpecimenComments", "AllowCytotechnologistToSignoutCases", "AllowCytotechSupervisorToSignoutCases", "IsSiteNotSurvivedTemplate", "IsSiteNotSubmittedTemplate", "DiagnosisTags", "DiagnosticSummaryHTML", "MicroscopicNotesHTML", "CommentSummaryHTML", "Message", "StatusCode"];

    // if (!sessionStorage.getItem('deploymentKey').match(/thx/i)) {
      dxDisplayColumns = [...dxDisplayColumns, ...["References Summary HTML", "Specimen Comments HTML", "Mapping Code"]];
      dxMappingColumns = [...dxMappingColumns, ...["ReferenceSummaryHTML", "SpecimenCommentsHTML", "MappingCode"]];
    // }
    dxDisplayColumns = [...dxDisplayColumns, ...["Code", "SlNo", "OrganizationId", "UserLevelTemplateUserId", "UserId"]];

    return {
      dxDisplayColumns: dxDisplayColumns,
      dxMappingColumns: dxMappingColumns,
      icdDisplayColumns: ["Mapping ID", "Diagnosis Template ID", "Body Site ID", "Sub Site ID", "Site Location ID", "ICD Codes 1", "ICD Codes 2", "ICD Codes 3", "Case Type", "Notes", "Status", "Code", "SlNo", "OrganizationId", "UserLevelTemplateUserId", "UserId"],
      icdMappingColumns: ["MappingId", "OrgDiagTemplateId", "BodySiteId", "SubSiteId", "SiteLocationId", "ICDCodes1", "ICDCodes2", "ICDCodes3", "CaseType", "Message", "StatusCode"]
    }
  }


  getDxSampleData() {
    return [{
      "Mapping ID": '1'
      , "Diagnosis Group Name": 'Benign'
      , "Site ID": '235'
      , "Diagnosis": 'TA HGD'
      , "Diagnosis Code": 'TA HGD'
      , "Diagnostic Summary": 'FRAGMENTS OF TUBULAR ADENOMA WITH HIGH GRADE DYSPLASIA.&#160'
      , "Microscopic Notes": 'Complete cross section of vas deferens'
      , "Search Code": '0'
      , "Display Order": '10050'
      , "Severity Order": '2'
      , "Report To Registry": '0'
      , "Schematic Display": 'Cancer'
      , "Display Color": 'Black'
      , "Display Pattern": 'Cross'
      , "Display Shape": 'Rectangle'
      , "RPT Cancer Registry Settings": '0,0'
      , "Reference": '2015'
      , "Reference Summary": 'Normal storage iron'
      , "Comment Code": 'EN'
      , "Comment Summary": 'case comments'
      , "Is Benign": '1'
      , "Abnormal": '1'
      , "Site Group Order": '1'
      , "Site Description": 'Site Desc'
      , "Default Diagnosis Template ID": '1'
      , "Reference Diagnosis Template ID": '1'
      , "Specimen Comments": 'A cancer that forms in the cells of the breasts.'
      , "Allow Cyto Tech To Sign-Out": '1'
      , "Allow Cyto Tech Supervisor To Sign-Out": '1'
      , "Site Not Survived Template": '1'
      , "Site Not Submitted Template": '1'
      , "Diagnosis Tags": 'Diagnosis'
      , "Diagnostics Summary HTML": '<p></p>'
      , "Microscopic Notes HTML": '<p></p>'
      , "Comment Summary HTML": '<p></p>'
      , "References Summary HTML": '<p></p>'
      , "Specimen Comments HTML": '<p></p>'
      , "Mapping Code": 'Nodular Type'
    }]
  }



  getIcdSampleData() {
    return [{
      "Diagnosis Template ID": "1",
      "Mapping ID": "1",
      "Body Site ID": "235",
      "Sub Site ID": "654",
      "Site Location ID": "237",
      "ICD Codes 1": "569.44",
      "ICD Codes 2": "79.53",
      "ICD Codes 3": "796.7"
    }]
  }

  constructor(public _snackbar: MatSnackBar, private _fb: FormBuilder, private dialog: MatDialog,
    private datashare: DataShareService,
    public DiagnosisTemplatesComponent: DiagnosisTemplatesComponent,
    private commonService: CommonService, private vitalHttpServices: VitalHttpServices, public ngxService: NgxUiLoaderService, public tabService: VitalAdminTabService, public activityService: ActivityTrackerService,public messages: Messages) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare,
    );
  }

  testForm = this._fb.group({
    frmpanel: ["", Validators.required],
    frmprocedure: ["", Validators.required],
    frmactive: true,
    frmtestsequence: ["", Validators.required],
    frmsequence: ["", Validators.required],
    frmcptcodes: ["", Validators.required],
    frmtestid: null,
    frmprocedureid: null,
  })



  SampleDataMinfields = [{
    groupname: 'Group Name'
    , siteid: '1'
    , diagnosiscode: 'Diagnosis code'
    , diagnosticsummary: 'Diagnostic summary'
    , microscopicnotes: 'Microscopic notes'
    , icdcodes1: '1'
    , icdcodes2: '2'
    , icdcodes3: '3'
    , reporttoregistry: '1'
    , schematicdisplay: '1'
    , displaycolor: 'red'
    , RptCancerRegistrySettings: '1 OR 0'
    , Abnormal: '1'
    // ,IsUserLevelTemplateEnabled:'1'
    // ,UserLevelTemplateUserId:'21400'
    , SpecimenComments: 'SpecimenComments'
    , ishidden: '1'
  }];

  SampleDataBulkUpdateTHX = [{
	  orgdiagtemplateid: "1234",
    orgparentid: "123",
    ishidden: "1",
    diagnosis: "BMHYP1",
    diagnosiscode:"BMHYP1",
    diagnosticsummary:"BENIGN MELANOCYTIC HYPERPLASIA ON SUN DAMAGED SKIN",
    microscopicnotes:"Test Benign Notes.",
    icd9codes:"123.4",
    displayorder:"1",
    severityorder:"123",
    Reporttoregistry: "1",
    RptCancerRegistrySettings: "0.0",
    isBillable:"1",
    isBenign: "1",
    Abnormal: "1",
    IsUserLevelTemplateEnabled:"1",
    UserLevelTemplateUserId:"1234",
    SpecimenComments:"SpecimenComments",
    DiagnosisGroupName:"Inflammatory Bowel Disease",
    AllowCytotechnologistToSignoutCases: "1",
    AllowCytotechSupervisorToSignoutCases: "1",
    IsSiteNotSurvivedTemplate:"1",
    IsSiteNotSubmittedTemplate:"1",
    DiagnosisTags:"<DiagnosisTemplateTags Tags/>",
    diagnosticsummaryHTML:"<p>Complete cross section of vas deferens</p>",
    microscopicnotesHTML:"<p>Complete cross section of vas deferens</p>",
    commentsummaryHTML:"<p>Comments for Test Template.</p>",
    referencesummaryHTML:"<p>Normal storage iron</p>",
    SpecimenCommentsHTML:"<p>Comments for Specimen Comments Template.</p>"
  }]

  SampleDataBulkUpdate = [{
    orgdiagtemplateid: "1234",
    orgparentid: "123",
    ishidden: "1",
    diagnosis: "BMHYP1",
    diagnosiscode:"BMHYP1",
    diagnosticsummary:"BENIGN MELANOCYTIC HYPERPLASIA ON SUN DAMAGED SKIN",
    microscopicnotes:"Test Benign Notes.",
    displayorder:"1",
    icd9codes:"123.4",
    severityorder:"123",
    Reporttoregistry: "1",
    RptCancerRegistrySettings: "0.0",
    isBillable:"1",
    isBenign: "1",
    Abnormal: "1",
    IsUserLevelTemplateEnabled:"1",
    UserLevelTemplateUserId:"1234",
    SpecimenComments:"SpecimenComments",
    DiagnosisGroupName:"Inflammatory Bowel Disease",
    AllowCytotechnologistToSignoutCases: "1",
    AllowCytotechSupervisorToSignoutCases: "1",
    IsSiteNotSurvivedTemplate:"1",
    IsSiteNotSubmittedTemplate:"1",
    DiagnosisTags:"<DiagnosisTemplateTags Tags/>",
    diagnosticsummaryHTML:"<p>Complete cross section of vas deferens</p>",
    microscopicnotesHTML:"<p>Complete cross section of vas deferens</p>",
    commentsummaryHTML:"<p>Comments for Test Template.</p>",
    referencesummaryHTML:"<p>Normal storage iron</p>",
    SpecimenCommentsHTML:"<p>Comments for Specimen Comments Template.</p>",
    CaseCommentsHTML: "<p>Comments for Template</p>",
    MappingCode:"Basal Cell Carcinoma, Nodular Type"
  }]

  BulkUpdate = [{}]

  ngOnInit(): void {
    this.getOrgCasetype();
    if ((this.templateData.cardtype.toLowerCase() == 'users') || sessionStorage.getItem("search_context").toLowerCase() == "userid" || (this.templateData.cardtype.toLowerCase() == 'pathologist') || (this.templateData.cardtype.toLowerCase() == 'physician'))
    {
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'User', 'value': this.templateData.menuURL }] })
    }
    else{
      this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
    }
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
    // this.loadUploadScreen();
  }
  ngOnChanges() {
    //to call multiple times onInit.
  }

  bulkUpdateTemplate(bulkUpdate) {
    let updateData = {
      'orgdiagtemplateid': "",
      'orgparentid': "",
      'ishidden': "",
      'diagnosis': "",
      'diagnosiscode':"",
      'diagnosticsummary':"",
      'microscopicnotes':"",
      'displayorder':"",
      'icd9codes':"",
      'Reporttoregistry': "",
      'RptCancerRegistrySettings': "",
      'isBillable':"",
      'isBenign': "",
      'Abnormal': "",
      'IsUserLevelTemplateEnabled':"",
      'UserLevelTemplateUserId':"",
      'SpecimenComments':"",
      'DiagnosisGroupName':"",
      'AllowCytotechnologistToSignoutCases': "",
      'AllowCytotechSupervisorToSignoutCases': "",
      'IsSiteNotSurvivedTemplate':"",
      'IsSiteNotSubmittedTemplate':"",
      'DiagnosisTags':"",
      'diagnosticsummaryHTML':"",
      'microscopicnotesHTML':"",
      'commentsummaryHTML':"",
      'referencesummaryHTML':"",
      'SpecimenCommentsHTML':"",
       'mappingcode':""
    }
    if (!sessionStorage.getItem('deploymentKey').match(/thx/i)) {
      // updateData['diagnosticsummaryHTML'] = ''
      // updateData['microscopicnotesHTML'] = ''
      // updateData['commentsummaryHTML'] = ''
      updateData['CaseCommentsHTML'] = ''
    }
    bulkUpdate.push(updateData);
    return bulkUpdate;
  }
  // Back to upload
  refreshGrid() {
    this.showDelete = true
    this.bulkUpload = false;
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.DiagnosisTemplatesComponent.uploadClicked = false;
    this.DiagnosisTemplatesComponent.detailsDisplay = false;
    this.DiagnosisTemplatesComponent.gridDisplay = true;
    // this.loadUploadScreen();
    let validatecardtype = sessionStorage.getItem('contextdata');
    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
      this.DiagnosisTemplatesComponent.refreshGrid();
    }
    else{
      this.DiagnosisTemplatesComponent.refreshBackData();
    }

  }
  //Download Bulk Update
  downloadBulkUpdate() {
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.id = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.id = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Diagnosis Template_' + 'Bulk Update_' + this.id + '.xlsx';
    let updateData = this.bulkUpdateTemplate([]);
    var ws = XLSX.utils.json_to_sheet(updateData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationDiagTemplates');
    if (!sessionStorage.getItem('deploymentKey').match(/thx/i)) {
      ws = XLSX.utils.json_to_sheet(this.SampleDataBulkUpdate)
    }
    else {
      ws = XLSX.utils.json_to_sheet(this.SampleDataBulkUpdateTHX)
    }
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }

  // Download All Fields
  downloadAllFields() {
    const dxAllFields = this.mapDisplayColumns([], this.getUploadColumns().dxDisplayColumns);
    const icdFields = this.mapDisplayColumns([], this.getUploadColumns().icdDisplayColumns);

    this.id = this.templateData.secondarykeys ?.OrganizationId || this.tabService.tabs[0].tabData.mainCard.Organizationid;
    const filename = `Diagnosis Template_All Fields_${this.id}.xlsx`;
    const wb = XLSX.utils.book_new();

    // Diag Template Sheet
    const dxSheetName = 'OrganizationDiagTemplates';
    this.appendSheetToWorkbook(wb, dxAllFields, dxSheetName, ["User ID"], !this.inData, this.userCard ? [] : ["Case Type"] );
    
    // Diag Template Sample Data
    this.appendSheetToWorkbook(wb, this.getDxSampleData(), 'DxSampleData');

    // ICD Code Sheet
    const icdSheetName = 'OrgDiagTemplatesICDCodes';
    this.appendSheetToWorkbook(wb, icdFields, icdSheetName, ["Diagnosis Template ID", "Case Type"]);

    // ICD Code Sample Data
    const icdSampleData = this.getIcdSampleData();
    this.appendSheetToWorkbook(wb, icdSampleData, 'ICDSampleData', ["Diagnosis Template ID"]);

    // Write to excel
    XLSX.writeFile(wb, filename);
  }

  appendSheetToWorkbook(workbook, data, sheetName, dxToDelete = [], deleteDx = true, icdToDelete = []) {
    if (deleteDx) {
      dxToDelete.forEach(field => delete data[0][field]);
    }
    icdToDelete.forEach(field => delete data[0][field]);

    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, ws, sheetName);
  }

  //#region Map display columns for Dx template or icd codes
  mapDisplayColumns(mappedData, displayColumn) {
    const ignoreColumns = new Set(["Notes", "Status", "Code", "SlNo", "OrganizationId", "UserLevelTemplateUserId", "UserId"]);
    const columnObject = {};

    displayColumn.forEach(column => {
      if (!ignoreColumns.has(column)) {
        columnObject[column] = "";
      }
    });

    mappedData.push(columnObject);
    return mappedData;
  }
  //#endregion

  // Download ICD Code Fields
  downloadICDFields() {
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.id = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.id = this.templateData.secondarykeys.OrganizationId;
    }
    let icdCodeData = this.getUploadColumns().icdDisplayColumns

    icdCodeData = icdCodeData.filter(va => !va.toString().match(/mapping id/i));
    if (!this.userCard){
      icdCodeData = icdCodeData.filter(va => !va.toString().match(/case type/i));
      let icdCodeFields = this.mapDisplayColumns([], icdCodeData);
      let filename = 'Diagnosis Template_' + 'ICDCode Fields_' + this.id + '.xlsx';
      var wb = XLSX.utils.book_new();
  
      var ws = XLSX.utils.json_to_sheet(icdCodeFields);
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrgDiagTemplatesICDCodes');
  
      let icdCodeSampleData = this.getIcdSampleData();
      delete icdCodeSampleData[0]["Mapping ID"];
      ws = XLSX.utils.json_to_sheet(icdCodeSampleData);
      XLSX.utils.book_append_sheet(wb, ws, "" + 'ICDSampleData')
  
      XLSX.writeFile(wb, filename);
    }
    else{
      icdCodeData = icdCodeData.filter(va => !va.toString().match(/case type/i));
      let icdCodeFields = this.mapDisplayColumns([], icdCodeData);
      let filename = 'Diagnosis Template_' + 'ICDCode Fields_' + this.id + '.xlsx';
      var wb = XLSX.utils.book_new();
  
      var ws = XLSX.utils.json_to_sheet(icdCodeFields);
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrgDiagTemplatesICDCodes');
  
      let icdCodeSampleData = this.getIcdSampleData();
      delete icdCodeSampleData[0]["CaseType"];
      ws = XLSX.utils.json_to_sheet(icdCodeSampleData);
      XLSX.utils.book_append_sheet(wb, ws, "" + 'ICDSampleData')
  
      XLSX.writeFile(wb, filename);
    }
    
  }

  onFileDropped($event) {
    this.onFileChange($event);
  }

  // Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.bulkUpdateFile = false
    this.UpdateColumnMisMatch = false
    // this.minFieldsFile = false
    this.missingColumn = [] 
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if(filename.toLowerCase().includes('bulk update')){
     this.bulkUpdateFile = true;
     this.updateGrid = true;
    }else{
      this.updateGrid = false;
    }
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.ngxService.start('BulkOperation');
          let uploadSheets = ["OrganizationDiagTemplates", "OrgDiagTemplatesICDCodes"];
          this.sheetsToSelect = this.sheetsToSelect.filter(va => !va.toString().toLowerCase().match(/sampledata/i));
          // let multiSheet = uploadSheets.every(va=> this.sheetsToSelect.includes(va));
          // if(multiSheet)
          let sheetData: object = this.checkDataExists(uploadSheets);
          if (this.checkIfUpdate(sheetData, uploadSheets[0])) {
            if (this.sheetsToSelect.length > 1) {
              let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationdiagtemplates')
              validSheet.length > 0 ? this.convertToJson(validSheet[0]) : this.openModal();
            }
            else {
              this.convertToJson(this.sheetsToSelect[0])
            }
          }
          else {
            try {
              this.mapExcelData(sheetData);
            }
            catch (error) {
              this.ngxService.stop('BulkOperation');
              this.removeGrid();
            }
          }
          this.ngxService.stop('BulkOperation');

        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  //#region  Checks if the user wants to upload or update 
  checkIfUpdate(sheetData, sheetToCheck) {
    return sheetData[sheetToCheck] ?.length > 0 && sheetData[sheetToCheck][0] ?.hasOwnProperty("orgdiagtemplateid");
  }
  //#endregion

  //#region Map data for upload later to be sent for validation
  mapExcelData(sheetData) {
    try {
      const diagTemplateData: Array<DiagnosisTemplate> = sheetData["OrganizationDiagTemplates"] || [];
      const icdCodeData: Array<OrgDiagTemplateICDCode> = sheetData["OrgDiagTemplatesICDCodes"] || [];
      const multiSheet = diagTemplateData.length > 0 && icdCodeData.length > 0;

      // Check if both arrays are empty
      if (icdCodeData.length && diagTemplateData.length && this.checkEmpty(diagTemplateData, icdCodeData)) {
        this.removeGrid();
        this._snackbar.open('Uploaded sheet does not contain data!', 'Close');
        return;
      }

      this.multiSheetCheck(diagTemplateData, icdCodeData, multiSheet);
    } catch (error) {
      console.error(error);
      this._snackbar.open(this.messages.provValidData, "Close");
      this.removeGrid();
    }
  }
  //#endregion


  checkEmpty(diagTemplateData, icdCodeData) {
    const isEmpty = (data) => !data[0] || Object.values(data[0]).every(value => value === '');
    return isEmpty(diagTemplateData) && isEmpty(icdCodeData);
  }

  //Send to different functions based on number of sheets selected
  async multiSheetCheck(diagTemplateData, icdCodeData, multiSheet) {
    let sheetName;
    if (multiSheet) {
      await this.multiSheetValidation(diagTemplateData, icdCodeData);
    } else {
      const dataToValidate = diagTemplateData.length ? diagTemplateData : icdCodeData;
      sheetName = diagTemplateData.length ? "OrganizationDiagTemplates" : "OrgDiagTemplatesICDCodes";
      await this.singleSheetValidation(dataToValidate, sheetName);
    }
  }


  async multiSheetValidation(diagTemplateData, icdCodeData) {
    diagTemplateData = this.mapAdditionalAttributes(diagTemplateData);
    diagTemplateData = this.changeKeysForRequest(diagTemplateData, this.getUploadColumns().dxDisplayColumns, this.getUploadColumns().dxMappingColumns);

    icdCodeData = this.mapAdditionalAttributes(icdCodeData);
    if(this.userCard){
      icdCodeData.forEach(va=> {
          va["Case Type"] = diagTemplateData[0]["CaseType"]
      })
    }

    let filteredData = icdCodeData.filter(data => data.StatusCode !== null);
    filteredData = [...filteredData, ...this.checkSheetMappings(icdCodeData, diagTemplateData)];
    icdCodeData = icdCodeData.filter(data => data.StatusCode === null);
    icdCodeData = this.changeKeysForRequest(icdCodeData, this.getUploadColumns().icdDisplayColumns, this.getUploadColumns().icdMappingColumns);

    let responseObject = await this.validateDxTemplates(diagTemplateData, icdCodeData, true);

    if (responseObject) {
      const hasDiagTemplateData = diagTemplateData.length > 0;
      const hasIcdCodeData = icdCodeData.length > 0 || filteredData.length > 0;

      if (hasDiagTemplateData && hasIcdCodeData) {
        responseObject["OrgDiagTemplatesICDCodes"] = [...this.changeKeysForDisplay(filteredData, this.getUploadColumns().dxDisplayColumns, this.getUploadColumns().dxMappingColumns), ...responseObject["OrgDiagTemplatesICDCodes"]];
        responseObject["OrgDiagTemplatesICDCodes"] = responseObject["OrgDiagTemplatesICDCodes"].sort((a, b) => a.SlNo > b.SlNo ? 1 : -1);
        this.showMultiGrid(responseObject["OrganizationDiagTemplates"], responseObject["OrgDiagTemplatesICDCodes"]);
      } else {
        this.showGrid(responseObject["OrganizationDiagTemplates"], "OrganizationDiagTemplates");
      }
    } else {
      this.removeGrid();
    }
  }

  showMultiGrid(diagTemplateData, icdCodeData) {
    const diagTempSheetHeaders = this.multiSheetHeaders["OrganizationDiagTemplates"];
    const icdTempSheetHeaders = this.multiSheetHeaders["OrgDiagTemplatesICDCodes"];

    this.multiSheetUpload = true;
    this.updateGrid = false;
    this.uploadGrid = new CollectionView();

    const diagExcelHeaders = this.mapHeaders([], diagTempSheetHeaders);
    const icdExcelHeaders = this.mapHeaders([], icdTempSheetHeaders);

    const diagCollectionView = this.getCollectionView(diagTemplateData);
    const icdCollectionView = this.getCollectionView(icdCodeData);


    const multiSheetData = this.multiSheetStructure(diagCollectionView, diagExcelHeaders, icdCollectionView, icdExcelHeaders);
    this.gridWidth =  this.getGridLength(diagExcelHeaders);
    this.multiSheetData = multiSheetData;
  }


  multiSheetStructure(diagTempData, diagExcelHeaders, icdCodeData, icdExcelHeaders) {
    const diagHeaders = [...new Set(diagExcelHeaders)].reverse();
    const icdHeaders = [...new Set(icdExcelHeaders)].reverse();
    const dxInvalidColumns = this.getInvalidColumns(diagHeaders, this.getUploadColumns().dxDisplayColumns);
    const icdInvalidColumns = this.getInvalidColumns(icdHeaders, this.getUploadColumns().icdDisplayColumns);


    return [{
      data: diagTempData,
      columns: diagHeaders,
      invalidColumns: dxInvalidColumns,
      label: "Diagnosis Templates"
    }, {
      data: icdCodeData,
      columns: icdHeaders,
      invalidColumns: icdInvalidColumns,
      label: "ICD Codes"
    }];
  }

  getInvalidColumns(sheetHeaders, displayColumns) {
    let invalidColumns: string[] = [];
    sheetHeaders.forEach(va => {
      if (!displayColumns.includes(va)) {
        invalidColumns.push(va);
      }
    })
    return invalidColumns.join(',')
  }

  getGridLength(excelHeaders) {
    const gridWidth = Math.min((170 * excelHeaders.length) + 37, 1300);
    return gridWidth;
  }

  mapHeaders(gridHeaders, sheetHeaders) {
    const headersToAdd = ['Notes'];
    if (!this.postUpload) {
      headersToAdd.push('Status');
    }
    return [...headersToAdd, ...sheetHeaders, ...gridHeaders];
  }

  showGrid(excelData, sheetName) {
    const sheetHeaders: any = this.multiSheetHeaders[sheetName] || [];
    const excelHeaders = ['Notes'];
    const displayColumns = (sheetName.match(/icd/i) ? this.getUploadColumns().icdDisplayColumns : this.getUploadColumns().dxDisplayColumns);


    if (this.postUpload) {
      excelHeaders.push('Status');
    }

    this.uploadHeaders = [...excelHeaders, ...sheetHeaders].reverse();
    const invalidColumns = this.getInvalidColumns(this.uploadHeaders, displayColumns);
    if (invalidColumns) {
      this.showInvalidColumns = true;
      this.invalidColumns = invalidColumns;
    }
    const gridView = this.getCollectionView(excelData);
    this.uploadGrid = gridView;
    this.gridWidth = this.getGridLength(sheetHeaders);
  }

  // Single sheet validations
  async singleSheetValidation(excelData, sheetName) {
    excelData = this.mapAdditionalAttributes(excelData);
    let filteredExcelData = excelData.filter(data => data.StatusCode !== null);
    let mappingKeys, userKeys;

    if (sheetName.toLowerCase().includes('icd')) {
      userKeys = this.getUploadColumns().icdDisplayColumns;
      mappingKeys = this.getUploadColumns().icdMappingColumns;


      filteredExcelData = [...filteredExcelData, ...this.checkSheetMappings(excelData)];
      excelData = excelData.filter(data => data.StatusCode === null);
      excelData = this.changeKeysForRequest(excelData, userKeys, mappingKeys);

      if (excelData.length > 0) {
        excelData = await this.validateICDCodes(excelData);
      }
    } else {
      userKeys = this.getUploadColumns().dxDisplayColumns;
      mappingKeys = this.getUploadColumns().dxMappingColumns;

      excelData = excelData.filter(data => data.StatusCode === null);
      excelData = this.changeKeysForRequest(excelData, userKeys, mappingKeys);

      if (excelData.length > 0) {
        excelData = await this.validateDxTemplates(excelData);
      }
    }

    excelData = [...excelData, ...filteredExcelData];
    excelData = this.changeKeysForDisplay(excelData, userKeys, mappingKeys);

    if (excelData) {
      this.postUpload = false;
      this.showGrid(excelData, sheetName);
    }
  }

  checkSheetMappings(icdCodeData, diagTempData?) {
    const filteredData = [];

    for (let i = 0; i < icdCodeData.length; i++) {
      if (diagTempData && diagTempData.length > 0) {
        const hasMappingId = diagTempData.some(data => data["MappingId"] == icdCodeData[i]["Mapping ID"]);
        if (!hasMappingId) {
          icdCodeData[i].StatusCode = 0;
          icdCodeData[i].Code = 0;
          icdCodeData[i].Message = this.messages.invalidMappingID;
          filteredData.push(icdCodeData[i]);
        }
      } else if (icdCodeData[i]["Mapping ID"] && !icdCodeData[i]["Diagnosis Template ID"]) {
        icdCodeData[i].StatusCode = 0;
        icdCodeData[i].Code = 0;
        icdCodeData[i].Message = this.messages.mandatoryFieldMissing;
        filteredData.push(icdCodeData[i]);
      }
    }

    return filteredData;
  }


  changeKeysForRequest(excelData, userKeys, mappingKeys) {
    const userToMappingMap = {};
    userKeys.forEach((key, index) => {
      userToMappingMap[key] = mappingKeys[index];
    });

    return excelData.map(data => {
      return Object.keys(data).reduce((modifiedObject, key) => {
        const newKey = userToMappingMap[key] || key;
        modifiedObject[newKey] = data[key];
        return modifiedObject;
      }, {});
    });
  }

  async validateICDCodes(icdCodeData) {
    try {
      this.ngxService.start();
      const response = await this.vitalHttpServices.validateICDCodes(icdCodeData, this.dbName).toPromise();

      if (response.content && response.content.length > 0) {
        icdCodeData = this.mapNotesAndStatus(icdCodeData, response.content);
      } else {
        this._snackbar.open("Please provide valid data!", 'Close');
        this.removeGrid();
        return null;
      }
    } catch (error) {
      console.error(error);
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      this.removeGrid();
      return null;
    } finally {
      this.ngxService.stop();
    }

    return icdCodeData;
  }


  mapNotesAndStatus(data, response) {
    if (!data) return [];

    data.forEach(val => {
      const responseObj = response.find(e => e.SlNo === val.SlNo);
      if (responseObj) {
        val.Message = responseObj.Message;
        val.StatusCode = responseObj.StatusCode;
        val.Code = val.StatusCode;
        if (this.postUpload) {
          const mappingStatus = ["Failure", "Success", "Ignored"];
          val.StatusCode = mappingStatus[val.StatusCode];
        }
      }
    });

    return data;
  }

  changeKeysForDisplay(data, displayColumns, mappingColumns) {
    const mappingToDisplayMap = {};
    mappingColumns.forEach((key, index) => {
      mappingToDisplayMap[key] = displayColumns[index];
    });

    return data.map(item => {
      return Object.keys(item).reduce((displayObject, key) => {
        const newKey = mappingToDisplayMap[key] || key;
        displayObject[newKey] = item[key];
        return displayObject;
      }, {});
    });
  }

  async validateDxTemplates(dxTempData, icdCodeData?, multi?) {
    this.ngxService.start();
    try {
      let requestData = {
        "OrganizationDiagTemplates": dxTempData,
        "OrgDiagTemplatesICDCodes": icdCodeData ? icdCodeData : []
      };

      const response = await this.vitalHttpServices.validateDxTemplates(requestData, this.dbName).toPromise();

      if (response.content && response.content.Table) {
        dxTempData = this.mapNotesAndStatus(dxTempData, response.content.Table);
        icdCodeData = this.mapNotesAndStatus(icdCodeData, response.content.Table1);

        dxTempData = this.changeKeysForDisplay(dxTempData, this.getUploadColumns().dxDisplayColumns, this.getUploadColumns().dxMappingColumns);
        icdCodeData = this.changeKeysForDisplay(icdCodeData, this.getUploadColumns().icdDisplayColumns, this.getUploadColumns().icdMappingColumns);

        this.ngxService.stop();

        if (multi) {
          return {
            "OrganizationDiagTemplates": dxTempData,
            "OrgDiagTemplatesICDCodes": icdCodeData
          };
        } else {
          return dxTempData;
        }
      } else {
        this._snackbar.open("Please provide valid data!", 'Close');
        this.removeGrid();
        this.ngxService.stop();
        return null;
      }
    } catch (error) {
      console.error(error);
      this._snackbar.open("Please provide valid data!", 'Close');
      this.removeGrid();
      this.ngxService.stop();
      return null;
    }
  }

  //#region Map additional attributes and do initial validate 
  mapAdditionalAttributes(excelData) {

    return excelData.map((data, index) => {
      const caseType = data["Case Type"] || data["CaseType"] || this.templateData.secondarykeys ?.casetype ?.toString();
      const message = data.Message ? 0 : null;

      this.doInitialValidation(excelData, data, index);

      return {
        ...data,
        SlNo: index + 1,
        OrganizationId: sessionStorage.getItem("org_id"),
        UserLevelTemplateUserId: !this.inData ? null : Number(this.inData),
        "Case Type": caseType,
        Message: data.Message ? data.Message : "",
        StatusCode: message,
        "UserId": (!sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid"))
      };
    });
  }
  //#endregion

  doInitialValidation(data, instanceValue, index) {
    let errorMessage = null;
    const caseTypeValues = ["CaseType", "Case Type"];
    const userKeys = ["User ID", "UserID"];

    if (this.checkDuplicate(data, instanceValue, index)) {
      errorMessage = this.messages.duplicateExists;
    }

    if (!this.userCard) {
      if (Object.keys(instanceValue).some(key => caseTypeValues.includes(key.trim().toLowerCase()))) {
        errorMessage = errorMessage || this.messages.invalidCaseType;
      } else if (!this.inData && Object.keys(instanceValue).some(key => userKeys.includes(key.trim().toLowerCase()))) {
        errorMessage = errorMessage || this.messages.invalidUserID;
      }
    } else {
      if (Object.keys(instanceValue).some(key => userKeys.includes(key.trim().toLowerCase())) && !this.userRoles.some(role => role.userid === instanceValue["User ID"])) {
        errorMessage = errorMessage || this.messages.invalidUserID;
      }
    }

    instanceValue.Message = errorMessage;
    instanceValue.StatusCode = errorMessage ? 0 : null;
    instanceValue.Code = errorMessage ? 0 : null;
  }

  checkDuplicate(data, instanceValue, index) {
    for (let i = 0; i < data.length; i++) {
      if (i < index) {
        if (JSON.stringify(data[i]) === JSON.stringify(instanceValue)) {
          return true;
        }
      }
    }
  }




  getCollectionView(excelData) {
    // for(let i = 0; i < excelData.length; i++){

    // }
    return new CollectionView(excelData, { pageSize: 10 });
  }

  //#region Check for invalid columns
  checkInvalidColumns(sentKeys, dxColumns) {
    return Array.isArray(sentKeys) && sentKeys.length > 0 && sentKeys.some(key => !dxColumns.includes(key));
  }
  //#endregion

  //#region Converts excel data to json for both sheets
  checkDataExists(sheetnames) {
    const DxTemplateSheets = {};
    for (const sheetName of sheetnames) {
      let sheetData = XLSX.utils.sheet_to_json(this.workBook.Sheets[sheetName], { defval: null });
      sheetData = this.removeEmptyKeyValues(sheetData);
      if (sheetData.length > 0) {
        this.multiSheetHeaders[sheetName] = Object.keys(sheetData[0]);
        sheetData = this.uniqueArray(sheetData, this.multiSheetHeaders[sheetName]);
        DxTemplateSheets[sheetName] = sheetData;
      }
    }
    return DxTemplateSheets;
  }
  //#endregion

 
  removeEmptyKeyValues(sheetData) {
    return sheetData.map(obj => {
      const newObj = {};
      Object.keys(obj).forEach(key => {
        if (!key.toString().toLowerCase().includes('empty')) {
          newObj[key] = obj[key];
        }
      });
      return (Object.values(newObj).some((va : any) => va) ?  newObj : {});
    }).filter(obj => Object.keys(obj).length > 0);
  }

  uniqueArray(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  //Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }

 
    if(this.bulkUpdateFile){
      if(Object.keys(tempExcelArr[0]).length < 2){
        this._snackbar.open("Please Provide Valid Data", "close")
        return;
      }
      this.uploadedHeaders = Object.keys(tempExcelArr[0]);
      let updatefields = this.bulkUpdateTemplate([])
      this.expectedHeaders = Object.keys(updatefields[0])
      // Object.keys(this.updateExcelColumns) 
       if(this.bulkUpdateFile){
          // Check if arrays are of equal length
          if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
            this.UpdateColumnMisMatch = true;
         }
         this.missingColumn = this.expectedHeaders.filter(header => !this.uploadedHeaders.includes(header));
         let extracolumns = this.uploadedHeaders.filter(header => !this.expectedHeaders.includes(header));
         if(extracolumns.length > 0){
          this._snackbar.open("Invalid excel", "Close") 
          return;
         }
      }
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          // primary[key] = value;
          if(!this.bulkUpdateFile){
            primary[key] = value;
          }else{
            if(value === null || value === 'null' || value ===''|| value === undefined){
              value = null;
              primary[key] = value;
            }else{
              primary[key] = value;
            }
          }
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse()
    temp[0].push("Notes")
    this.sheetHeader = temp[0].filter(va => !va.toString().toLowerCase().match(/empty/i));
    if (this.sheetHeader.filter(element => element == 'orgdiagtemplateid').length > 0) {
      this.bulkUpload = true;
    }
    else {
      this.bulkUpload = false;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    // Add the key and value to the primary object in tempArray
    if(this.UpdateColumnMisMatch){
      for(let i=0; i<tempExcelArr.length; i++){
       for(let j=0; j<this.missingColumn.length; j++){
         tempExcelArr[i][this.missingColumn[j]] = '';
       }
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    this.excelDataArray.sort((a, b) => a.groupname < b.groupname ? -1 : a.groupname > b.groupname ? 1 : 0)
    let i = 1;
    this.excelDataArray.find((d): any => {
      if (this.templateData.secondarykeys === undefined) {
        Object.assign(d, {
          slno: i, tablename: 'OrganizationDiagTemplates', organizationid: sessionStorage.getItem("org_id"),
          userleveltemplateuserid: !this.inData ? null : Number(this.inData), isuserleveltemplateenabled: 1
        });
        i++;
      } else {
        if (this.inData) {
          Object.assign(d, {
            slno: i, tablename: 'OrganizationDiagTemplates', organizationid: this.templateData.secondarykeys.OrganizationId, casetype: this.templateData.cardtype.toString(),
            userleveltemplateuserid: !this.inData ? null : Number(this.inData), isuserleveltemplateenabled: !this.inData ? null : 1
          });
          i++;
        }
        else {
          Object.assign(d, {
            slno: i, tablename: 'OrganizationDiagTemplates', organizationid: this.templateData.secondarykeys.OrganizationId, casetype: this.templateData.cardtype.toString()
          });
          i++;
        }
      }
    });
    this.validateExcelData(this.excelDataArray);
  }

  async getOrgCasetype() {
    let query = this.SubMenuCardModel.GetQuery('getorgcasetypes');
    let queryVariable = { "orgid": sessionStorage.getItem('org_id') };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vitalHttpServices.GetData(queryResult).toPromise().then(res => {
      if (!res.errors) {
        this.caseTypeArray = [];
        this.caseTypeArray = res.data.submenuData;
        //this.refreshGrid();
      }
    }, error => {
      console.log(error)
    })
  }

  closeCasetypeModel() {
    this.invalidCasetypeFlag = false;
  }

  //#region User/Pathologist Cardtype: casetype validation
  validateCasetype(dataArray?) {
    this.excelDataArray = dataArray;
    if (!this.bulkUpload) {
      let temp1 = []
      for (let i = 0; i < this.caseTypeArray.length; i++) {
        temp1.push(this.caseTypeArray[i].casetype.toLowerCase())
      }
      let configuredCasetype = [];
      configuredCasetype = this.excelDataArray.filter(item => temp1 ?.includes(item ?.casetype ?.toLowerCase()))
      let invalidCasetype = [];
      let temp = [];
      invalidCasetype = this.excelDataArray.filter(item => !temp1 ?.includes(item ?.casetype ?.toLowerCase()))
      for (let i = 0; i < invalidCasetype.length; i++) {
        temp.push(invalidCasetype[i].casetype)
      }
      let displayInvalidCasetype = [...new Set(temp)];
      if (displayInvalidCasetype.length > 0) {
        this.repeatedNames = '';
        let invalidCasetypeList = '';
        for (let i = 0; i < displayInvalidCasetype.length; i++) {
          invalidCasetypeList += displayInvalidCasetype[i] + ', '
        }
        if (configuredCasetype.length > 0) {
          this.excelDataArray = configuredCasetype;
          //this.uploadData();
        } else {
          this.excelDataArray = []
        }
        this.repeatedNames = invalidCasetypeList.substring(0, invalidCasetypeList.length - 2);
        this.invalidCasetypeFlag = true;
      }
      else {
        if (configuredCasetype.length > 0) {
          this.excelDataArray = configuredCasetype;
          //this.uploadData();
        }

      }
    }
    return this.excelDataArray;
  }
  // Close Modal


  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }

  // Cancel
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
    this.hideupload = true;
    this.gridPage = false;
    this.multiSheetUpload = false;
    this.uploadGrid = new CollectionView;
    this.multiSheetData = new Array<object>();
  }

  // Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  // Validate Excel
  validateExcelData(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.invalidColumns = '';
    this.showInvalidColumns = false;
    if (!this.bulkUpload) {
      this.vitalHttpServices.ValidateBulkUploadDiagnosisTemplatesData(dataArray).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.content.length > 0) {
            result = result.content;
            this.ngxService.stop();
            if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }
            if(this.bulkUpdateFile){
             for (let i = 0; i < excelArr.length; i++) {
              if (this.missingColumn.some(e=> e.toLowerCase() == 'orgdiagtemplateid')) {
                this._snackbar.open("Please provide valid data", 'Failed');
                this.ngxService.stop();
                return;
              }
             } 
            }

            if (!result[0]['NoteMessage'] && result[0]["InvalidColumns"]) {
              // this.showInvalidColumns = false;
              this._snackbar.open("Uploaded template is invalid.Please try again with the correct template", 'Failed');
              // this._snackbar.open('Please upload a valid excel sheet','Close')
              return;
            }
            else {
              dataArray.filter(e => {
                result.filter(r => {
                  if (r.SlNo === e.slno) {
                    e.notes = r.NoteMessage;
                  }
                });
              });
              this.ngxService.stop();
              //let newarray=this.validateCasetype(dataArray)
              this.AddGridData(dataArray);
            }
            // else {
            //   this.ngxService.stop();
            //   this._snackbar.open("Uploaded template is invalid.Please try again with the correct template", 'Failed');
            // }

          }
          else {
            this._snackbar.open("Uploaded sheet does not have correct data", 'Failed');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", 'Failed');
      });
    }
    else {
      let i = 1;
      excelArr.find(d => {
        if (this.templateData.secondarykeys === undefined) {
          Object.assign(d, {
            slno: i, tablename: 'OrganizationDiagTemplates', organizationid: sessionStorage.getItem("org_id"), casetype: null,
            userleveltemplateuserid: !this.inData ? null : Number(this.inData), isuserleveltemplateenabled: 1
          });
          i++;
        } else {
          Object.assign(d, {
            slno: i, tablename: 'OrganizationDiagTemplates', organizationid: this.templateData.secondarykeys.OrganizationId, casetype: this.templateData.cardtype.toString(),
            userleveltemplateuserid: !d.userleveltemplateuserid ? null : d.userleveltemplateuserid, isuserleveltemplateenabled: !this.inData ? null : 1
          });
          i++;
        }
      });
      dataArray = this.convertObjKeysToLower(excelArr);
      this.vitalHttpServices.ValidateBulkUpdateDiagnosisTemplatesData(dataArray).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.content.length > 0) {
            result = result.content;
            this.ngxService.stop();
            if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }
            dataArray.filter(e => {
              result.filter(r => {
                if (r.SlNo === e.slno) {
                  e.notes = r.NoteMessage;
                }
              });
            });
            this.ngxService.stop();
          }

          else {
            this._snackbar.open("Uploaded sheet does not have correct data", 'Failed');
          }
          this.AddGridData(dataArray);
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", 'Failed');
      });
    }
  }

  // Grid Data
  AddGridData(data) {
    data = this.validateCasetype(data);
    this.ngxService.start();
    this.sheetHeader;
    this.gridDisplay = true;
    this.gridArray = [];
    this.updateGrid = true;
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i)) {
              let flag = false;
              if (![ 'diagnosiscode' , 'orgdiagtemplateid', 'diagnosis'].includes(key.toString().toLowerCase())){
                if (!this.bulkUpdateFile && value != null) {
                  if (value.toString().toLowerCase().trim() == 'null') {
                    primary['notes'] = 'Null values exist!' 
                  }else if(data[i]['notes'] == ''){
                    primary['notes'] = 'Valid'
                  }
                  primary[key] = value;
                }
                if(this.bulkUpdateFile){
                  if(key.toString().toLowerCase() == 'notes'){
                    primary[key] = primary['notes'] == 'Mandatory field is missing' ? 'Mandatory field is missing' :value  ; 
                  }else{
                    primary[key] = value;
                  }
                }
              }
              if ([ 'diagnosiscode' , 'orgdiagtemplateid', 'diagnosis'].includes(key.toString().toLowerCase())) {
                if (!this.bulkUpdateFile && (value == null || value.toString().toLowerCase().trim() == 'null' || value == '')) {
                  this.excelDataArray[i]["notes"] = 'Mandatory field is missing';
                  primary['notes'] = 'Mandatory field is missing';                
                }else if(this.bulkUpdateFile){
                  if(!this.missingColumn.find(e=> e.toLowerCase() == key.toString().toLowerCase())) {
                    if(value == null || value.toString().toLowerCase().trim() == 'null' || value == ''){
                      this.excelDataArray[i]["notes"] = 'Mandatory field is missing';
                      primary['notes'] = 'Mandatory field is missing';                    
                    }
                  }
                }
                primary[key] = value;
              }
            }
          }
          this.gridArray.push(primary);
        }
        this.excelDataArray = this.gridArray;
        this.gridData = new CollectionView(this.gridArray)
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }

    }
    this.ngxService.stop();

    return false;
  }

  disableApprovebtn() {
    if (this.updateGrid) {
      return this.gridArray.every(item => {
        const notes = item['notes'] ? item['notes'].toString().toLowerCase() : '';
        return notes.includes('diagnosis template already exists for this entity') ||
          notes.includes('mandatory field is missing') ||
          notes.includes('diagnosis template id is not available in this entity.');
      });
    } else {
      if (this.multiSheetUpload) {
        const multiSheetData: any = this.multiSheetData;
        const diagTempData = multiSheetData[0].data;
        const icdCodeData = multiSheetData[1].data;
        const diagTempInvalidColumns = multiSheetData[0].invalidColumns;
        const icdCodeInvalidColumns = multiSheetData[1].invalidColumns;

        let returnValue = diagTempData._src.every(item => item.Code == 1) && icdCodeData._src.every(item => item.Code == 1) && !diagTempInvalidColumns && !icdCodeInvalidColumns;
        return !returnValue;
      } else {
        const uploadData = this.uploadGrid;
        let returnValue = uploadData._src.every(item => item.Code == 1) && !this.showInvalidColumns;
        return !returnValue;
      }
    }
  }

  // Delete Row
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }

  deleteRecord(grid, row, label) {
      if (label.match(/template/i)) {
        grid[0].data.remove(row.dataItem);
        grid[1].data._view = grid[1].data._view.filter(va => va["Mapping ID"] != row.dataItem["Mapping ID"]);
        grid[1].data = new CollectionView(grid[1].data._view, { pageSize: 10 });
        if (grid[1].data._view.length === 0) {
          this.handleEmptyGrid(grid[0]);
        }
        else if(grid[0].data._view.length === 0){
          this.removeGrid();
        }
      } else {
        grid[1].data.remove(row.dataItem);
        if (grid[1].data._view.length === 0) {
          this.handleEmptyGrid(grid[0]);
        }
      }
    this._snackbar.open('Deleted successfully', 'Close');
  }

  handleEmptyGrid(grid) {
    this.ngxService.start();
    this.multiSheetUpload = false;
    this.uploadHeaders = grid.columns;
    this.uploadGrid = grid.data;
    this.ngxService.stop();
    if (grid.data._view.length === 0) {
      this.removeGrid();
    }
  }

  // Upload test Data
  uploadTestData() {
    if (!this.updateGrid) {
      this.uploadBulkData()
      return;
    }
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["notes"]) {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exists/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i)) {
          errorcount++
        } else if (this.excelDataArray[i]["notes"].toString() == "Site id is not valid") {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === "null" || value === null) {
              this.excelDataArray[i][key] = null;
            }else if( value === "" ){
              if(this.bulkUpdateFile){
                this.excelDataArray[i][key] = value;
              }else{
                this.excelDataArray[i][key] = null;
              }
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          if (!this.bulkUpload) {
            for (let i = 0; i < this.excelDataArray.length; i++) {
              if (this.templateData.secondarykeys === undefined) {
                this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
                this.excelDataArray[i]["organizationid"] = sessionStorage.getItem("org_id").toString()
                //this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString()
              } else {
                this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
                this.excelDataArray[i]["organizationid"] = this.templateData.secondarykeys.OrganizationId.toString()
                this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString()
              }
            }
          }
          else {
            for (let i = 0; i < this.excelDataArray.length; i++) {
              if (this.templateData.secondarykeys === undefined) {
                this.excelDataArray[i]["modifiedby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
                this.excelDataArray[i]["organizationid"] = sessionStorage.getItem("org_id").toString()
                //this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString()
              } else {
                this.excelDataArray[i]["modifiedby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
                this.excelDataArray[i]["organizationid"] = this.templateData.secondarykeys.OrganizationId.toString()
                this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString()
              }
            }

          }
        }
      }
      let dataArray = [];
      this.ngxService.start();
      let userid = this.inData ? this.inData : -1;
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      if (!this.bulkUpload) {
        this.vitalHttpServices.BulkUploadDiagnosisTemplates(dataArray).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result.content.length > 0) {
              result = result.content;
              this.postUpload = true;
              this.postDownload = true;
              this.showDelete = false;
              this.inData = null;
              this.commonService.auditDetails('','',[], this.excelDataArray, 'Upload' , this.templateData, '');
              // this.commonService.createActivityTracker('Created', userid, 'Bulk Upload Diagnosis Templates', 'Audit', dataArray, {});
              //--start
              this.excelDataArray = [];
              this.excelDataArray = this.convertObjKeysToLower(result);
              for (let i = 0; i < result.length; i++) {
                this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
                if (result[i]["NoteMessage"].match(/already exists/)) {
                  this.excelDataArray[i]["status"] = "Ignored"
                }
                else {
                  this.excelDataArray[i]["status"] = result[i]["STATUS"]
                }
              }
              //--end
              this.ngxService.start();
              this.bulkUpload = false;
              this.AddGridData(this.excelDataArray);
              this.ngxService.stop();
              let dataExistsCount = 0;
              for (let i = 0; i < result.length; i++) {
                if (Object.values(result).every(function (item: any) {
                  return item.STATUS == "Ignored" ? true : false
                })) {
                  dataExistsCount++;
                }
              }
              if (dataExistsCount == result.length) {
                this._snackbar.open('Diagnosis Templates already exist!', 'Close');
              }
              else {
              }
            }
            else {
              this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
            }
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong.Please try again', 'Close');
          console.error(error)
        })
      }
      else {
        this.vitalHttpServices.BulkUpdateDiagnosisTemplates(dataArray).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result.content.length > 0) {
              result = result.content;
              this.postUpload = true;
              this.postDownload = true;
              this.showDelete = false;
              this.commonService.auditDetails('','',[], this.excelDataArray, 'Bulk Update' , this.templateData, '');
              // this.commonService.createActivityTracker('Update', userid, 'Bulk Update Diagnosis Templates', 'Audit', dataArray, {});
              for (let i = 0; i < result.length; i++) {
                this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
                if (result[i]["NoteMessage"].match(/already exists/)) {
                  this.excelDataArray[i]["status"] = "Ignored"
                }
                else {
                  this.excelDataArray[i]["status"] = result[i]["Status"]
                }
              }
              this.excelDataArray = this.convertObjKeysToLower(this.excelDataArray);
              this.ngxService.start();
              this.AddGridData(this.excelDataArray);
              this.ngxService.stop();
              let dataExistsCount = 0;
              for (let i = 0; i < result.length; i++) {
                if (Object.values(result).every(function (item: any) {
                  return item.STATUS == "Ignored" ? true : false
                })) {
                  dataExistsCount++;
                }
              }
              if (dataExistsCount == result.length) {
                this._snackbar.open('Diagnosis Templates already exist!', 'Close');
              }

              else {
                ///this._snackbar.open('Data uploaded successfully', 'Close');
              }
              this.bulkUpload = false;
            }
            else {
              this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
            }
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong.Please try again', 'Close');
          console.error(error)
        })
      }
    }
  }

  // Export Grid data
  ExportExcel(flex, multiSheetData?) {
    if (!this.templateData.secondarykeys) {
      this.id = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.id = this.templateData.secondarykeys.OrganizationId;
    }

    if (!this.updateGrid) {
      if (this.multiSheetUpload) {
        this.multiSheetExport(multiSheetData, this.id);
        return;
      }
      this.singleSheetExport(flex, this.id);
      return;
    }

    let excel = [];
    flex.rows.find(e => {
      delete e._data["notemessage"];
      delete e._data['slno'];
      excel.push(e._data);
    });
    let filename = 'Diagnosis Templates_' + this.id + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationDiagTemplates');
    XLSX.writeFile(wb, filename);
  }

  multiSheetExport(sheets, organizationId) {
    const diagTemplateData = cloneDeep(sheets[0].data._src);
    const icdCodesData = cloneDeep(sheets[1].data._src);

    const filename = 'Diagnosis Templates_' + organizationId + '.xlsx';
    const wb = XLSX.utils.book_new();

    
    diagTemplateData.__wrapped__.forEach(data => {
      return Object.keys(data).forEach(key => {
        if(key == "User ID"){
          let newKey = key;
          newKey = "UserLevelTemplateUserId";
          data[newKey] = data[key];
          delete data[key];
        }
      }, {});
    })

    const formatAndAppendSheet = (data, sheetName,changeKey?) => {
      if(changeKey){
        data.__wrapped__ = this.mapUserID(data.__wrapped__);
      }
      const formattedData = this.formatSheetData(data.__wrapped__);

      const ws = XLSX.utils.json_to_sheet(formattedData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    };

    formatAndAppendSheet(diagTemplateData, 'OrganizationDiagTemplates',true);
    formatAndAppendSheet(icdCodesData, 'OrgDiagTemplatesICDCodes');

    XLSX.writeFile(wb, filename);
  }

  mapUserID(templateSheetData){
    templateSheetData.forEach(data => {
      return Object.keys(data).forEach(key => {
        if(key == "User ID"){
          let newKey = key;
          newKey = "UserLevelTemplateUserId";
          data[newKey] = data[key];
          delete data[key];
        }
      }, {});
    })
    return templateSheetData;
  }

  formatSheetData(data) {
    return data.map(row => {
      const { SlNo, Code, ...formattedRow } = row;
      return formattedRow;
    }).reverse();
  }

  singleSheetExport(flex, id) {
    let uploadedData = cloneDeep(flex.collectionView._src);
    uploadedData.__wrapped__ = this.mapUserID(uploadedData.__wrapped__);
    uploadedData = this.formatSheetData(uploadedData.__wrapped__);
    let filename = 'Diagnosis Templates_' + id + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      uploadedData.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.getSheetName(uploadedData));
    XLSX.writeFile(wb, filename);

  }

  getSheetName(uploadedData) {
    for (const key of Object.keys(uploadedData[0])) {
      if (key.toLowerCase().includes('icd')) {
        return "OrgDiagTemplatesICDCodes";
      }
    }
    return "OrganizationDiagTemplates";
  }


  //#region to show notes
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      // if(e.panel.rows[0].dataItem && (e.panel.rows[0].dataItem=='notes'||e.panel.rows[0].dataItem=='status')){
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().includes('Site id is not valid') || value == 'Mandatory field is missing' || value == 'Null' || value == 'Diagnosis template already exists for this Entity.' || value.toString().includes('Diagnosis Templates already exist!')
          || value.toString().includes('Invalid') || value.toString().includes('accepts only')
          || value.toString().includes('Diagnosis name exist.')
          || value.toString().includes('Site does not exist')
          || value.toString().includes('Diagnosis template id is not available in this Entity.')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('New template added') || value == 'Success' || value.toString().includes('Update Successfully')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('already exist')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
      //  }
    }
  }

  //#regin to show notes with color
  addNoteColor(flexGrid: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs, excelSheetData) {
    let columnKey = e.panel["_cols"][e.col]?._binding?._key;
    if (!columnKey || !['notes', 'status'].includes(columnKey.toLowerCase().trim())) {
      return;
    }
    if (e.panel == flexGrid.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().includes('Site id is not valid') || value == 'Mandatory field is missing' || value == 'Null' || value == 'Diagnosis template already exists for this Entity.' || value.toString().includes('Diagnosis Templates already exist!')
          || value.toString().includes('Invalid') || value.toString().includes('accepts only')
          || value.toString().includes('Diagnosis name exist.')
          || value.toString().includes('Site does not exist')
          || value.toString().includes('Diagnosis template id is not available in this Entity.')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('New template added') || value == 'Success' || value.toString().includes('Update Successfully')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('already exist')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }
  //#endregion


  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  showUploadBtns() {
    return this.excelDataArray.length > 0 || this.multiSheetData || this.uploadGrid;
  }

  uploadBulkData() {
    if (this.multiSheetUpload) {
      this.multiSheetApprove(this.multiSheetData);
    }
    else {
      this.singleSheetApprove(this.uploadGrid);
    }
  }

  async multiSheetApprove(sheetData) {
    if (!sheetData || sheetData.length !== 2) {
      return;
    }

    const approveData = this.approveDisableMulti(sheetData) || !this.showInvalidColumns || this.multiSheetData.some((va: any) => va.invalidColumns);
    if (!approveData) {
      return;
    }

    const [diagSheetName, icdSheetName] = sheetData.map(sheet => sheet.label);
    const dxUserKeys = this.getUploadColumns().dxDisplayColumns;
    const dxMappingKeys = this.getUploadColumns().dxMappingColumns;
    const icdUserKeys = this.getUploadColumns().icdDisplayColumns;
    const icdMappingKeys = this.getUploadColumns().icdMappingColumns;

    const diagTemplateData = this.changeKeysForRequest(approveData[diagSheetName], dxUserKeys, dxMappingKeys);
    const icdCodeData = this.changeKeysForRequest(approveData[icdSheetName], icdUserKeys, icdMappingKeys);

    try {
      const responseObject = await this.approveDxTemplates(diagTemplateData, icdCodeData, true);

      if (responseObject) {
        if (responseObject["OrgDiagTemplatesICDCodes"] && responseObject["OrgDiagTemplatesICDCodes"].length > 0) {
          this.showMultiGrid(responseObject["OrganizationDiagTemplates"], responseObject["OrgDiagTemplatesICDCodes"]);
        } else {
          this.showGrid(responseObject["OrganizationDiagTemplates"], "OrganizationDiagTemplates");
        }
      } else {
        this.removeGrid();
      }
    } catch (error) {
      console.error("Error during multiSheetApprove:", error);
      // Handle error gracefully, maybe show a message to the user
    }
  }

  approveDisableMulti(sheetData) {
    let approveData = {};
    sheetData.forEach(va => {
      approveData[va.label] = va.data._src;
    })
    let data = [];

    Object.values(approveData).forEach((va: any) => {
      data = [...data, ...va]
    })
    return this.checkValidData(data, approveData);
  }

  async singleSheetApprove(uploadData) {
    if (!uploadData || !Array.isArray(uploadData._src) || uploadData._src.length === 0) {
      this._snackbar.open("Please upload valid data!", "Close");
      return;
    }

    let icdCodeUpload = false;
    let approveData = uploadData._src;
    approveData = this.checkValidData(approveData);
    if (!approveData) {
      this._snackbar.open("Please upload only valid data!", "Close");
      return;
    }

    let userKeys, mappingKeys, excelData, sheetName;
    if (Object.keys(approveData[0]).some(va => va.toString().toLowerCase().trim().includes('icd'))) {
      icdCodeUpload = true;
    }

    if (icdCodeUpload) {
      sheetName = "OrgDiagTemplatesICDCodes";
      userKeys = this.getUploadColumns().icdDisplayColumns;
      mappingKeys = this.getUploadColumns().icdMappingColumns;
    } else {
      sheetName = "OrganizationDiagTemplates";
      userKeys = this.getUploadColumns().dxDisplayColumns;
      mappingKeys = this.getUploadColumns().dxMappingColumns;
    }
    
    excelData = this.changeKeysForRequest(approveData, userKeys, mappingKeys);
    try {
      if (icdCodeUpload) {
        excelData = await this.approveIcdCodes(excelData);
      } else {
        excelData = await this.approveDxTemplates(excelData);
      }
      
      if (excelData) {
        this.showGrid(excelData, sheetName);
      } else {
        this.removeGrid();
      }
      this.postUpload = true;
    } catch (error) {
      console.error("Error during singleSheetApprove:", error);
      this._snackbar.open("An error occurred while processing your request", "Close");
      this.removeGrid();
    }
  }

  checkValidData(values, approveData = null) {
    const invalidData = values.some(va => va.Status !== 1);

    if (invalidData) {
      this._snackbar.open("Please upload only valid data!", "Close");
      return false;
    }

    return approveData || values;
  }

  async approveDxTemplates(dxTempData, icdCodeData = null, multi = false) {
    try {
      this.ngxService.start();
      const requestData = {
        "OrganizationDiagTemplates": dxTempData,
        "OrgDiagTemplatesICDCodes": icdCodeData ? icdCodeData : []
      };

      const response = await this.vitalHttpServices.approveDiagnosisTemplates(requestData, this.dbName).toPromise();

      if (response.content && response.content.Table) {
        this.postUpload = true;
        dxTempData = this.mapNotesAndStatus(dxTempData, response.content.Table);
        icdCodeData = this.mapNotesAndStatus(icdCodeData, response.content.Table1);
        dxTempData = this.changeKeysForDisplay(dxTempData, this.getUploadColumns().dxDisplayColumns, this.getUploadColumns().dxMappingColumns);
        icdCodeData = this.changeKeysForDisplay(icdCodeData, this.getUploadColumns().icdDisplayColumns, this.getUploadColumns().icdMappingColumns);
        let returnRes;
        if (multi) {
          returnRes =  {
            "OrganizationDiagTemplates": dxTempData,
            "OrgDiagTemplatesICDCodes": icdCodeData
          };
          this.commonService.auditDetails('','',[],returnRes,'Upload',this.templateData,[]);
        } else {
          returnRes = dxTempData;
          this.commonService.auditDetails('','',[],returnRes,'Upload',this.templateData,[]);
        }
        return returnRes;
      } else {
        this._snackbar.open("Error uploading the excel!", 'Close');
        this.removeGrid();
      }
    } catch (error) {
      console.error(error);
      this._snackbar.open("Error uploading the excel!", 'Close');
      this.removeGrid();
    } finally {
      this.ngxService.stop();
    }
  }


  async approveIcdCodes(icdCodeData) {
    this.ngxService.start();
    let success = false;

    try {
      const response = await this.vitalHttpServices.approveICDCodes(icdCodeData, this.dbName).toPromise();

      if (response.content && response.content.length > 0) {
        this.postUpload = true;
        icdCodeData = this.mapNotesAndStatus(icdCodeData, response.content);
        icdCodeData = this.changeKeysForDisplay(icdCodeData, this.getUploadColumns().icdDisplayColumns, this.getUploadColumns().icdMappingColumns);
        success = true;
        this.commonService.auditDetails('','',[],icdCodeData,'Upload',this.templateData,[]);
      } else {
        this._snackbar.open("Error uploading the excel!", 'Close');
        this.removeGrid();
      }
    } catch (error) {
      console.error(error);
      this.removeGrid();
      this._snackbar.open("Error uploading the excel!", 'Close');
    } finally {
      this.ngxService.stop();
    }

    if (success) {
      return icdCodeData;
    }
  }

  gridInit(grid){
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
}
