import {
  CommonModule,
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
  NgLocaleLocalization,
  NgLocalization,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as wjcCore from '@grapecity/wijmo';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { CookieService } from 'ngx-cookie-service';
// import { NgxEditorModule } from 'ngx-editor';

import { FrequencyModalComponent } from '../app/client/Templates/frequency-modal/frequency-modal.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './appmaterial.module';
import { EditUserComponent } from './base/common/org-table/edit-user/edit-user.component';
import { OrgTableComponent } from './base/common/org-table/org-table.component';
import { ConfirmComponent } from './base/popup/confirm/confirm.component';
import { EntitySearchComponent } from './base/search/entitysearch.component';
import { SessionComponent } from './base/session/session.component';
import { TabName } from './base/Tab/tab-heading.directive';
import { Tab } from './base/Tab/tab.directive';
import { Tabset } from './base/Tab/tabset.component';
import { ContentContainerDirective } from './client/commoncomponents/tab-container-directive/content-container.directive';
import { BaseSettingsTemplate } from './client/commoncomponents/templateclasses/basesettings';
import { MainCardModel } from './client/DbModel/MainCardModel';
import {
  RowPipe,
  VitalGenericMainCardComponent,
} from './client/GenericCard/vital-general-main-card.component';
import { InitialsearchpageComponent } from './client/initialsearchpage/initialsearchpage.component';
import { VitalAdminTabService } from './client/tab.service';
import { AccountLogoUploadComponent } from './client/Templates/AccountLogoUpload/AccountLogoUpload.component';
import { AccountsAssociationComponent } from './client/Templates/accounts-association/accounts-association.component';
import { ActionbtnComponent } from './client/Templates/actionbtn/actionbtn.component';
import { AddIPAddressComponent } from './client/Templates/AddIPAddress/AddIPAddress.component';
import { AddUserToOrgComponent } from './client/Templates/AddUserToOrg/AddUserToOrg.component';
// import { AdequacyCodesComponent } from './client/Templates/adequacy-codes/adequacy-codes.component';
import { AllUsersComponent } from './client/Templates/allusers/allusers.component';
import { BulkUploadDiagnosisTemplateComponent } from './client/Templates/bulk-upload-diagnosis-template/bulk-upload-diagnosis-template.component';
import { BulkUploadTestsComponent } from './client/Templates/bulk-upload-tests/bulk-upload-tests.component';
// import { CaseCommentsComponent } from './client/Templates/case-comments-component/case-comments.component';
import { CaseProfilesComponent } from './client/Templates/case-profiles/case-profiles.component';
import { CasesComponent } from './client/Templates/cases/cases.component';
import { CasetypeDetailsComponent } from './client/Templates/casetype-details/casetype-details.component';
import { CasetypeFlagsComponent } from './client/Templates/casetype-flags/casetype-flags.component';
import { CasetypeTemplateContainerComponent } from './client/Templates/CasetypeTemplateContainer/Casetypetemplatecontainer.component';
import { CustomReportGridContainerComponent } from './client/Templates/CustomReportGridContainer/customreportgrid.component';
import { CustomRulesGridContainerComponent } from './client/Templates/CustomRulesGridContainer/customrulesgrid.component';
import { DefaultStainsComponent } from './client/Templates/default-stains/default-stains.component';
import { DesktoptemplateComponent } from './client/Templates/desktoptemplate/desktoptemplate.component';
import { DiagnosisTemplatesComponent } from './client/Templates/diagnosis-templates/diagnosis-templates.component';
import { DisclaimersComponent } from './client/Templates/disclaimers/disclaimers.component';
import { ExecuteSqlComponent } from './client/Templates/ExecuteSql/Executesql.component';
import { ExportCaseDetailsComponent } from './client/Templates/export-case-details/export-case-details.component';
import { GenerateVdIdComponent } from './client/Templates/GenerateVdId/GenerateVdId.component';
import { GridContainerComponent } from './client/Templates/GridContainer/gridcontainer.component';
import { GridContainerWithCheckBoxComponent } from './client/Templates/GridContainerWithCheckBox/GridContainerWithCheckBox.component';
import { GridguidcontainerComponent } from './client/Templates/gridguidcontainer/gridguidcontainer.component';
import { GrossingAttributesComponent } from './client/Templates/grossing-attributes/grossing-attributes.component';
import { HGridContainerComponent } from './client/Templates/HGridContainer/hgridcontainer.component';
import { HL7LogsComponent } from './client/Templates/HL7Logs/HL7Logs.component';
import { IdentityFormatsComponent } from './client/Templates/identity-formats/identity-formats.component';
import { InterfaceComponent } from './client/Templates/interface/interface.component';
import { LicensureInfoComponent } from './client/Templates/LicensureInfo/LicensureInfo.component';
// import { ManageCollectionMethodsComponent } from './client/Templates/manage-collection-methods/manage-collection-methods.component';
// import { ManageGrossTemplatesComponent } from './client/Templates/manage-gross-templates/manage-gross-templates.component';
import { ManageIcd9codesComponent } from './client/Templates/manage-icd9codes/manage-icd9codes.component';
import { ManageReportRecipientComponent } from './client/Templates/manage-report-recipient/manage-report-recipient.component';
// import { ManageSitesComponent } from './client/Templates/manage-sites/manage-sites.component';
import { ManagerReportGridContainerComponent } from './client/Templates/ManageScheduledReports/managescheduledreports.component';
import { MaterialTemplateContainerComponent } from './client/Templates/MaterialTemplateContainer/materialtemplatecontainer.component';
import { MigrateBulkUsersComponent } from './client/Templates/migrate-bulk-users/migrate-bulk-users.component';
import { OrganizationAttributesComponent } from './client/Templates/organization-attributes/organization-attributes.component';
import {
  openDialogIgnoreKeys,
  OrgCompareComponent,
} from './client/Templates/OrgCompare/OrgCompare.component';
import {
  openDialogIgnoreKeysActionBtn,
  OrgCompareActionBtnComponent,
} from './client/Templates/OrgCompareActionBtn/OrgCompareActionBtn.component';
import { PathologySignUploadComponent } from './client/Templates/pathology-sign-upload/pathology-sign-upload.component';
import { QualityAttributesComponent } from './client/Templates/quality-attributes/quality-attributes.component';
import { ReadOnlyGridContainerComponent } from './client/Templates/ReadOnlyGridContainer/readonlygridcontainer.component';
import { ResultNestedComponent } from './client/Templates/ResultNested/resultnested.component';
import { ResultsectionComponent } from './client/Templates/resultsection/resultsection.component';
import { RevisionReasonComponent } from './client/Templates/RevisionReason/RevisionReason.component';
import { SearchPanelComponent } from './client/Templates/SearchPanel/searchpanel.component';
import { SettingsTemplateContainerComponent } from './client/Templates/SettingsTemplateContainer/settingstemplatecontainer.component';
import { SetupCustomerEnableCasetypeComponent } from './client/Templates/setup-customer-enable-casetype/setup-customer-enable-casetype.component';
import { SetupNewCustomerComponent } from './client/Templates/setup-new-customer/setup-new-customer.component';
import { SetupNewEntitiesComponent } from './client/Templates/setup-new-entities/setup-new-entities.component';
import { SetupNewFacilityComponent } from './client/Templates/setup-new-facility/setup-new-facility.component';
import { SetupNewLocationComponent } from './client/Templates/setup-new-location/setup-new-location.component';
import { TemplateContainerComponent } from './client/Templates/TemplateContainer/templatecontainer.component';
import { TextCompareComponent } from './client/Templates/TextCompare/TextCompare.component';
import { UserPersonalInfoComponent } from './client/Templates/UserPersonalInfo/UserPersonalInfo.component';
import { VdgenricComponent } from './client/Templates/vdgenric/vdgenric.component';
import { VDInformationComponent } from './client/Templates/VDInformation/vdinformation.component';
import { VDTemplateUpload } from './client/Templates/VDTemplate/vdtemplateupload.component';
import { VitalbridgeComponent } from './client/Templates/vitalbridge/vitalbridge.component';
import { VitalMenuComponent } from './client/Vital Search Menu/vital-menu.component';
import { VitalAdminSearchConnectorComponent } from './client/vital-admin-search-connector/vital-admin-search-connector.component';
import { HomeLayoutComponent } from './common/layout/home-layout.component';
import { LoginLayoutComponent } from './common/layout/login-layout.component';
import { AuthGuard } from './core/services/auth.guard';
import { AuthInterceptor } from './core/services/auth.interceptor';
import { CommonService } from './core/services/commonservices';
import { ExcelService } from './core/services/excel.service';
import { HeaderInterceptor } from './core/services/header.interceptor';
import { InjectorService } from './core/services/Injectorservices';
import { WindowRef } from './core/services/NewWindowservices';
import { VitalHttpServices } from './core/services/VitalHttpServices';
import { DndDirective } from './core/utility/dnd.directive';
import { TrimDirective } from './core/utility/trim.directive';
import { EllipsisPipe } from './core/utility/ellipsis.pipe';
import { ClickStopPropagation } from './core/utility/eventpropagation.directive';
import { ExternalUrlDirective } from './core/utility/extredirect.component';
import { ReplaceFilterTextPipe } from './core/utility/replacefiltertext.pipe';
import { SafePipe } from './core/utility/safe.pipe';
import { SearchFilterPipe } from './core/utility/searchfilter.pipe';
import { SplitCamelCasePipe } from './core/utility/splitcamelcase.pipe';
import { FilterPipe, TripFilterPipe, searchFilterPipetemplate } from './core/utility/tripfilter.pipe';
// import { ReviseTemplatesComponent } from './client/Templates/revise-templates/revise-templates.component';
import { ReviseNotesTemplatesComponent } from './client/Templates/revise-notes-templates/revise-notes-templates.component';
import { BulkUploadOrderResultCodeComponent } from './client/Templates/bulk-upload-order-result-code/bulk-upload-order-result-code.component';
import { JsonFormatComponent } from './client/Templates/json-format/json-format.component';
import { MandatoryFieldsComponent } from './client/Templates/mandatory-fields/mandatory-fields.component';
// import { MicroscopicCodesComponent } from './client/Templates/microscopic-codes/microscopic-codes.component';
import { UserRoleMgmtComponent } from './client/Templates/user-role-mgmt/user-role-mgmt.component';
import { UserDefinedFieldsComponent } from './client/Templates/user-defined-fields/user-defined-fields.component';
import { CasetypeAttributesComponent } from './client/Templates/casetype-attributes/casetype-attributes.component';
import { ActivityTrackerComponent } from './client/Templates/activity-tracker/activity-tracker.component';
import { RequisitionProfilesComponent } from './client/Templates/requisition-profiles/requisition-profiles.component';
import { CasetypeConfigurationComponent } from './client/Templates/casetype-configuration/casetype-configuration.component';
// import { ScreeningCommentsComponent } from './client/Templates/screening-comments/screening-comments.component';
import { ManageScreeningTypesComponent } from './client/Templates/manage-screening-types/manage-screening-types.component';
import { CompendiumAdminComponent } from './client/Templates/compendium-admin/compendium-admin.component';
import { ManageUserComponent } from './client/Templates/manage-user/manage-user.component';
// import { ManageRejectionCommentsComponent } from './client/Templates/manage-rejection-comments/manage-rejection-comments.component';
import { ManageTabSequenceComponent } from './client/Templates/manage-tab-sequence/manage-tab-sequence.component';
import { ReportBuilderComponent } from './client/Templates/report-builder/report-builder.component';
import { MultisheetPopupComponent } from './base/popup/multisheet-popup/multisheet-popup.component';
import { ActivityTrackerLibModule } from 'activity-tracker-lib';
// import { ManageReflexRulesComponent } from './client/Templates/manage-reflex-rules/manage-reflex-rules.component';
// import { ManagePasswordPolicyComponent } from './client/Templates/ManagePasswordPolicy/manage-password-policy.component';
import { DialogService } from './core/services/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from './base/popup/dialog/dialog.component';
import { TiltleDirective } from './client/Templates/manage-reflex-rules/title.directive';
import { NewQualityAttributesComponent } from './client/Templates/new-quality-attributes/new-quality-attributes.component';
import { PreviewDialogComponent } from './base/popup/preview-dialog/preview-dialog.component';
import { NewFlagsComponent } from './client/Templates/new-flags/new-flags.component';
import { SwitchComponent } from './commoncomponents/switch/switch.component';
// import { NewBulkUploadTestsComponent } from './client/Templates/new-bulk-upload-tests/new-bulk-upload-tests.component';

import { ExportUploadCopyComponent } from './commoncomponents/export-upload-copy/export-upload-copy.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BlockCopyPasteDirective } from './core/utility/block-copy-paste.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NewCaseProfilesComponent } from './client/Templates/new-case-profiles/new-case-profiles.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';


import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { TemplatesViewComponent } from '../app/base/popup/templates-view/templates-view.component';
// import { DeletionTemplatesComponent } from './client/Templates/deletion-templates/deletion-templates.component';
// import { CronEditorModule } from 'ngx-cron-editor';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.threeStrings,
  fgsType: SPINNER.threeStrings,
  fgsSize: 80,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar  direction
  pbThickness: 0, // progress bar thicknesss
  overlayColor: 'rgba(20,20,20,.6)',
};
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from 'ngx-ui-loader';
//import { CommonOptionsComponent } from './client/Templates/common-options/common-options.component';
// import { ManageAutoAssignmentRulesComponent } from './client/Templates/manage-auto-assignment-rules/manage-auto-assignment-rules.component';
// import { NotAvailableDirective } from './core/utility/not-available.directive';
import { CountOfGroupItemsDirective } from './core/utility/count-of-group-items.directive';
import { NumericInputDirective } from './core/utility/NumericInput.directive';
import { OrganizationCaseTagsComponent } from './client/Templates/organization-case-tags/organization-case-tags.component';
import { CaseTagsDialogComponent } from './client/Templates/case-tags-dialog/case-tags-dialog.component';
import { CaseTagsFilterComponent } from './client/Templates/case-tags-filter/case-tags-filter.component';
import { sharedModule } from './shared/shared.module';
import { ManageCustomListingComponent } from './client/Templates/manage-custom-listing/manage-custom-listing.component';
import { ManageByUsersComponent } from './client/Templates/manage-by-users/manage-by-users.component';
// import { ManagePathologistPrivilegeComponent } from './client/Templates/manage-pathologist-privilege/manage-pathologist-privilege.component';

import { ManagePathologistPrivilegeComponent } from './client/Templates/manage-pathologist-privilege/manage-pathologist-privilege.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { TemplatesListingComponent } from './templates-listing/templates-listing.component';
import { ManageSequenceWheelComponent } from './client/Templates/manage-sequence-wheel/manage-sequence-wheel.component';
// import { ManageCategoriesComponent } from './client/Templates/manage-categories/manage-categories.component';
// import { DiagnosisReferencesComponent } from './client/Templates/diagnosis-references/diagnosis-references.component';
// import { CustomAbbreviationsComponent } from './client/Templates/custom-abbreviations/custom-abbreviations.component';
import { ShareModule } from './labadmin/components/share/share.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReplicateOrgComponent } from './client/Templates/replicate-organization/replicate-organization.component';
import { DefaultRoleSettingsComponent } from './client/Templates/default-role-settings/default-role-settings.component';
import { CronEditorModule } from 'ngx-cron-editor';
// import { SequesterTemplatesComponent } from './client/Templates/sequester-templates/sequester-templates.component';
import { MarkdownModule } from 'ngx-markdown';
import { VaEntityAttributesComponent } from './client/Templates/va-entity-attributes/va-entity-attributes.component';
import { ManageTabSequence2Component } from './client/Templates/manage-tab-sequence2/manage-tab-sequence2.component';
import { StoreModule } from '@ngrx/store';
import { BredCrumReducer } from './labadmin/store/reducers/navbar.reducer';
// import { ConfirmLabadminComponent } from './base/popup/confirm-labadmin/confirm-labadmin.component';

// import { CorelationTemplatesComponent} from './client/Templates/corelation-templates/corelation-templates.component'
// import { TubetypeTemplatesComponent } from './client/Templates/tubetype-templates/tubetype-templates.component';
// import { NomenclatureTemplatesComponent } from './client/Templates/nomenclature-templates/nomenclature-templates.component';
import { LabAdminDailogComponent } from './base/popup/lab-admin-dailog/lab-admin-dailog.component';
import { VitalInsightsComponent } from './client/Templates/vital-insights/vital-insights.component';
import { ReportBuilderPopupComponent } from './client/Templates/popup/report-builder-popup.component';
import { ShimmerModule } from './shimmer/shimmer.module';
import { WorkgroupComponent } from './client/Templates/workgroup/workgroup.component';
import { ManageOrganizationComponent } from './client/Templates/manage-organization/manage-organization.component';
import { NewcasetypetemplatesComponent } from './client/Templates/newcasetypetemplates/newcasetypetemplates.component';
import { UpdatereporttemplateComponent } from './client/Templates/updatereporttemplate/updatereporttemplate.component';
import { NewAccountsAssociationComponent } from './client/Templates/new-accounts-association/new-accounts-association.component';
import { ManageReportSignatoriesComponent } from './client/Templates/manage-report-signatories/manage-report-signatories.component';
import { ManageVdTemplateComponent } from './client/Templates/manage-vd-template/manage-vd-template.component';
import { NewManageRuleOutsComponent } from './client/Templates/new-manage-rule-outs/new-manage-rule-outs.component';
import { ManageRuleOutsComponent } from './client/Templates/manage-rule-outs/manage-rule-outs.component';
import { NewAssociationFormComponent } from './client/Templates/new-association-form/new-association-form.component';
import { VitalAxisAssignRepresentativesComponent } from './client/Templates/vital-axis-assign-representatives/vital-axis-assign-representatives.component';
import { RepresentativeCardComponent } from './client/Templates/vital-axis-assign-representatives/representative-card/representative-card.component';
import { ManageMedicationsComponent } from './client/Templates/manage-medications/manage-medications.component';
import { ManageJsonEditorComponent } from './client/Templates/manage-json-editor/manage-json-editor.component';
import { ObservationAndQuantitationTemplateComponent } from './client/Templates/observation-and-quantitation-template/observation-and-quantitation-template.component';
import { ClinicalInformationComponent } from './client/Templates/clinical-information/clinical-information.component';
wjcCore.setLicenseKey('admin.vitalaxis.com|titansprintvitaladmin.vitalaxis.com|jupiterqcvitaladmin.vitalaxis.com|jupiterdevvitaladmin.vitalaxis.com|admin3.vitalaxis.com|titanqcvitaladmin.vitalaxis.com|adminbeta3.vitalaxis.com|adminstage3.vitalaxis.com|jupitersprintvitaladmin.vitalaxis.com|adminbeta.vitalaxis.com|supportqcvitaladmin.vitalaxis.com|reportsqcvitaladmin.vitalaxis.com|adminstaging.vitalaxis.com,883829444474513#B0NZPdlRmclRXawVnas46bj9ycphXYsFGdpZnLulWbkFGbhRXa6NWcyVGdpBXdqxSbvNmLzlGehxWY4lmdu8WatRWYsFGdpZHdulmcwNnbhRXa4xSbvNmLzlGehxWY4lmdu8WatRWYiojIz5GRiwiIzlGeBxWY4lmViojIh94QiwiIzETN4cDN4QDN9IDOzgDOiojIklkIs4XXbpjInxmZiwiIxY7MyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZzFFRnRmRyRzaNJDWYRUY4UjTZ3WbyMzdi5mUsNzY5M4ZYFHOhNjNahnW5MmZXhkQrdVQ5pXOyVlZiJ5V5Z6MUJndohjT8ckTGhTV9QnbJhESGNjNIdka4cjWQFXTiFmTxEGNvYXTzEnRopVTwhURahnZLl7V7MnVyhkYCF7avYUZZ3mYhFlWwR5ZKBjYuNVU6BlYJlVZh3UStFzUS9URXJHb4EFb7QUUIdzUtBTN79UaYV4V6UWbwdTdxJnYllWdztGZKRWa6wEcW56N8EGbvYjRwFnbmFXN7EWbzl4MGRUS4tEdIpnRxp7aNFkQ8RmZJdkUQpGakhTQYt6LyljNONFey2GbykWSHlnW4kDV6U4V8FEO8Y6Ry5kUWxmM4YmczF6a5BDVFJkY4BjbspGdl3Wb7R6SWZTbP5kRItCdJN4SqRWdxl7bqh6dnFmSwEFSqpWUDNmI0IyUiwiI5IUQ5ADMENjI0ICSiwyM7YzN4ETM4YTM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsICNxITMwEDI7EzMwQjMwIjI0ICdyNkIsISbvNmLzlGehxWY4lmducmbpdWY4Nnbp5GZhxSbvNmLzlGehxWY4lmdu8WatRWYsFGdpZ7YxNHdy3GclJHLt36YuMXa8FGbhRXa69ibp5GZhxWY4lmdjFHdy3GcwV7cs46bj9ycphXYsFGdpZnLhRXZi9WatRWYs46bj9ycphXYsFGdpZnLulWbkFGbhRXa6RnbpJHczJXZ4lGc5pGLt36YuMXa8FGbhRXa69yMldWY4Nnbp5GZhxSbvNmLzlGehxWY4lmduMTY4VmYulWbkFGLt36YuMXa8FGbhRXa69ibp5GZhxWY4lmdjFnbhRXa4xSbvNmLzlGehxWY4lmduMjbp5GZhxSbvNmLzlGehxWY4lmdu8WatRWYgFSd');

import { CassetteTemplatesComponent } from './client/Templates/cassette-templates/cassette-templates.component';
import { ManagePayersComponent } from './client/Templates/manage-payers/manage-payers.component';
import { ManagePayersLookupComponent } from './client/Templates/manage-payers-lookup/manage-payers-lookup.component';
import { ACSSerachOptimizationComponent } from './client/Templates/acs-serach-optimization/acs-serach-optimization.component';
@NgModule({
  declarations: [
    AppComponent,
    AllUsersComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    EntitySearchComponent,
    SessionComponent,
    InitialsearchpageComponent,
    VitalGenericMainCardComponent,
    VitalMenuComponent,
    TemplateContainerComponent,
    SearchPanelComponent,
    GridContainerComponent,
    GridContainerWithCheckBoxComponent,
    CustomRulesGridContainerComponent,
    CasetypeTemplateContainerComponent,
    SettingsTemplateContainerComponent,
    CustomReportGridContainerComponent,
    RevisionReasonComponent,
    ReadOnlyGridContainerComponent,
    VitalAdminSearchConnectorComponent,
    ContentContainerDirective,
    HGridContainerComponent,
    VDTemplateUpload,
    MaterialTemplateContainerComponent,
    ResultNestedComponent,
    RowPipe,
    BaseSettingsTemplate,
    Tabset,
    Tab,
    TabName,
    ClickStopPropagation,
    SearchFilterPipe,
    // searchFilterPipetemplate,
    EllipsisPipe,
    SplitCamelCasePipe,
    AddUserToOrgComponent,
    TripFilterPipe,
    FilterPipe,
    DndDirective,
    TrimDirective,
    ExternalUrlDirective,
    ActionbtnComponent,
    InterfaceComponent,
    ResultsectionComponent,
    DesktoptemplateComponent,
    VitalbridgeComponent,
    VDInformationComponent,
    UserPersonalInfoComponent,
    LicensureInfoComponent,
    HL7LogsComponent,
    AddIPAddressComponent,
    GenerateVdIdComponent,
    ExecuteSqlComponent,
    VdgenricComponent,
    OrgCompareComponent,
    TextCompareComponent,
    SafePipe,
    openDialogIgnoreKeys,
    openDialogIgnoreKeysActionBtn,
    OrgCompareActionBtnComponent,
    ManageReportRecipientComponent,
    GridguidcontainerComponent,
    PathologySignUploadComponent,
    AccountLogoUploadComponent,
    ManagerReportGridContainerComponent,
    ConfirmComponent,
    SetupNewLocationComponent,
    SetupNewCustomerComponent,
    AccountsAssociationComponent,
    FrequencyModalComponent,
    // CaseCommentsComponent,
    CasesComponent,
    // ManageGrossTemplatesComponent,
    ExportCaseDetailsComponent,
    OrgTableComponent,
    MigrateBulkUsersComponent,
    DiagnosisTemplatesComponent,
    SetupCustomerEnableCasetypeComponent,
    DisclaimersComponent,
    CaseProfilesComponent,
    ReplaceFilterTextPipe,
    BulkUploadTestsComponent,
    EditUserComponent,
    SetupNewFacilityComponent,
    VaEntityAttributesComponent,
    // ManageSitesComponent,
    // AdequacyCodesComponent,
    SetupNewEntitiesComponent,
    GrossingAttributesComponent,
    // ManageCollectionMethodsComponent,
    BulkUploadDiagnosisTemplateComponent,
    OrganizationAttributesComponent,
    JsonFormatComponent,
    CasetypeFlagsComponent,
    QualityAttributesComponent,
    ManageRuleOutsComponent,
    NewManageRuleOutsComponent,
    DefaultStainsComponent,
    CasetypeDetailsComponent,
    // ReviseTemplatesComponent,
    ReviseNotesTemplatesComponent,
    IdentityFormatsComponent,
    BulkUploadOrderResultCodeComponent,
    ManageIcd9codesComponent,
    UserDefinedFieldsComponent,
    UserRoleMgmtComponent,
    MandatoryFieldsComponent,
    // MicroscopicCodesComponent,
    CasetypeAttributesComponent,
    ActivityTrackerComponent,
    RequisitionProfilesComponent,
    CasetypeConfigurationComponent,
    // ScreeningCommentsComponent,
    ManageScreeningTypesComponent,
    CompendiumAdminComponent,
    OrganizationCaseTagsComponent,
    ManageUserComponent,
    // ManageRejectionCommentsComponent,
    ManageTabSequenceComponent,
    ReportBuilderComponent,
    MultisheetPopupComponent,
    // ManageReflexRulesComponent,
    // ManagePasswordPolicyComponent,
    DialogComponent,
    TiltleDirective,
    //TemplatesListingComponent,
    ManageSequenceWheelComponent,
  //  CommonOptionsComponent,
    //ManageAutoAssignmentRulesComponent,
    BlockCopyPasteDirective,
    //ManageAutoAssignmentRulesComponent,
    NewQualityAttributesComponent,
    PreviewDialogComponent,
    NewFlagsComponent,
    SwitchComponent,
    // NewBulkUploadTestsComponent,
    NumericInputDirective,
    ExportUploadCopyComponent,
    NewCaseProfilesComponent,
    // NotAvailableDirective,
    CountOfGroupItemsDirective,
    CaseTagsDialogComponent,
    CaseTagsFilterComponent,
    ManageVdTemplateComponent,
    // searchFilterPipetemplate,

    // DeletionTemplatesComponent,
    // ManageCategoriesComponent,
    // DiagnosisReferencesComponent,
    // TemplatesViewComponent,
    // CustomAbbreviationsComponent,
    // ManageCustomListingComponent,
    ManageByUsersComponent,
    DefaultRoleSettingsComponent,
    // SequesterTemplatesComponent,
    ManageTabSequence2Component,
    // CorelationTemplatesComponent,
    // TubetypeTemplatesComponent,
    // NomenclatureTemplatesComponent,
    VitalInsightsComponent,

    ReplicateOrgComponent,
    // ConfirmLabadminComponent,
    // ManagePathologistPrivilegeComponent,
    LabAdminDailogComponent,
    WorkgroupComponent,
    ManageOrganizationComponent,
    NewcasetypetemplatesComponent,
    UpdatereporttemplateComponent,
    NewAccountsAssociationComponent,
    ManageReportSignatoriesComponent,
    NewAssociationFormComponent,
    VitalAxisAssignRepresentativesComponent,
    RepresentativeCardComponent,
    ManageMedicationsComponent,
    ManageJsonEditorComponent,
    ObservationAndQuantitationTemplateComponent,
    ClinicalInformationComponent,
    CassetteTemplatesComponent,
    ManagePayersComponent,
    ManagePayersLookupComponent,
    ACSSerachOptimizationComponent,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    BrowserModule,
    AppMaterialModule,
    // NgxEditorModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // sharedModule,
    CommonModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    WjCoreModule,
    WjGridModule,
    WjInputModule,
    WjGridFilterModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ActivityTrackerLibModule,
    CronEditorModule,
    MonacoEditorModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    MarkdownModule.forRoot(),
    ScrollingModule,
    NgbModule,
    ShareModule,
    ShimmerModule,
    StoreModule.forRoot({ breadcrum: BredCrumReducer }),
    StoreDevtoolsModule.instrument({ name:'VitalLabAdmin',maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    VitalAdminTabService,
    FormGroupDirective,
    CommonService,
    ExcelService,
    ActionbtnComponent,
    VitalHttpServices,
    InjectorService,
    MainCardModel,
    WindowRef,
    ResultNestedComponent,
    // CaseCommentsComponent,
    // ManageGrossTemplatesComponent,
    // ManageRejectionCommentsComponent,
    // DeletionTemplatesComponent,
    // ScreeningCommentsComponent,
    ReviseNotesTemplatesComponent,
    // ReviseTemplatesComponent,
    GridContainerWithCheckBoxComponent,
    AddUserToOrgComponent,
    VitalMenuComponent,
    AllUsersComponent,
    UserPersonalInfoComponent,
    DiagnosisTemplatesComponent,
    BulkUploadDiagnosisTemplateComponent,
    AccountsAssociationComponent,
    FrequencyModalComponent,
    CookieService,
    ActionbtnComponent,
    SetupNewFacilityComponent,
    SetupNewEntitiesComponent,
    ActivityTrackerComponent,
    EntitySearchComponent,
    SetupNewLocationComponent,
    CasetypeConfigurationComponent,
    HomeLayoutComponent,
    DialogService,
    ReportBuilderPopupComponent,
    ReportBuilderComponent,
    [DatePipe],
    TripFilterPipe,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000, horizontalPosition: 'end' },
    },
    { provide: NgLocalization, useClass: NgLocaleLocalization },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Tabset,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
  // entryComponents: [
  //   SessionComponent,
  //   openDialogIgnoreKeys,
  //   openDialogIgnoreKeysActionBtn,
  //   OrgCompareActionBtnComponent,
  //   ConfirmComponent
  // ],
})
export class AppModule {}
