import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as XLSX from 'xlsx';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { ThemePalette } from '@angular/material/core';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { Subscription, take } from 'rxjs';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';

export interface Task {
  color: ThemePalette;
}

declare var $: any;
@Component({
  selector: 'app-screening-comments',
  templateUrl: './screening-comments.component.html',
  styleUrls: ['./screening-comments.component.scss']
})
export class ScreeningCommentsComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };


  @Input()
  templateData: any;
  public SubmenuCardModel;
  public DeploymentKeys = [];

  public SubMenuCardModel;
  gridheader: string[];
  gridPage: boolean = true;
  gridData: wjcCore.CollectionView<any>;
  gridShowDiv: boolean;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  gridWidth: number = 0;
  noDataFound: boolean = false;
  showPaginationMainGrid: boolean;
  ScrngCommentsGridData: wjcCore.CollectionView<any>;
  showGrid: boolean = false;
  postUpload: boolean;
  selector: Selector = null;
  selectedCopyData: any = [];
  selectedData: any = [];
  addEditScreen: boolean = false;
  masterLevel: boolean = false;
  action: string = '';
  currentData: any;
  rowId: any;
  allFields = [{}]
  mandatoryFields = [{}]
  gridDisplay: boolean;
  showDelete: boolean;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  dragnDrop: boolean;
  copyBtn: boolean;
  deletebtn: boolean;
  sampleData = [{
    templateName: 'Name',
    description: 'Description',
    isDefault: '0/1',
    IsActive: '0/1'
  }]
  sampleDataUpdate = [{
    ID: '777',
    templateName: 'Name',
    description: 'Description',
    isDefault: '0/1',
    IsActive: '0/1'
  }]
  BulkUpdate = [{}]
  @ViewChild('grid') gridRef: WjFlexGrid;
  ScreeningCommentsForm = this._fb.group({
    frmname: "",
    frmdesc: "",
    frmsequence: "",
    frmIsdefault: false,
    frmIsActive: true,
  })

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  copyScreeningCommentsForm = this._fb.group({
    frmDepKey: ["", Validators.required],
    frmOrgType: ["", Validators.required],
    frmOrganization: ["", Validators.required],
    frmOrgID: [""],
    frmcpyasactive: true,
  })
  copyDataClicked: boolean = false;
  public searchResult: Array<any> = [];
  editClicked: any;
  bulkUpload: any = false;
  uploadClicked: boolean = false;
  workBook: any;
  sheetsToSelect: string[];
  excelDataArray: any[] = [];
  sheetHeader: any[];
  copyFlag: any;
  showInvalidColumns: boolean = false;
  invalidColumns: any = '';
  orgList: any = [];
  organizationList: any = [];
  searchInput: string = '';
  checkStatus = false;
  srcOrgid: string;
  columns = [
    new ColDef('Description', 'Description'),
    new ColDef('TemplateName', 'Template Name'),
    new ColDef('OrganizationID', 'OrganizationID'),
    new ColDef('Id', 'Id'),
    new ColDef('CaseType', 'CaseType'),
    new ColDef('Default', 'Default'),
    new ColDef('Active', 'Active'),
    new ColDef('Sequence', 'Sequence'),
    new ColDef('CreatedBy', 'CreatedBy'),
    new ColDef('CreatedDate', 'CreatedDate'),
    new ColDef('ModifiedBy', 'ModifiedBy'),
    new ColDef('ModifiedDate', 'ModifiedDate'),
  ];
  searchuserlist: any[];
  searchUserInput: string;
  organizationId: any;
  gridHeaderList: any;
  listedGridHeader: any;
  listHeaderbackup: any;
  ViewScreen: boolean = false;
  receivedObject: any = {};
  auditableColumns: any;
  saveOrEditCompleted: any;
  sourceApp: any;
  userSessionDetails: any;
  storeSub: Subscription = new Subscription();
  activityEntity: any;
  selectedCaseType: any = '';
  expectedHeaders: any = [];
  uploadedHeaders: any = [];
  UpdateColumnMisMatch: boolean = false;
  missingColumn: any = [];
  bulkUpdateFile: boolean = false;

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices, private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, private templateHeaders: TemplateHeadersService,private activityService: ActivityTrackerService,
    private labAdminService: LabadminService, private labAdminSessionService: LabadminSessionService, private store: Store<{ breadcrum: [] }>) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }

  async ngOnInit() {
    let checkLaunchUrl=window.location.href.split('/#/')[1]
   if(checkLaunchUrl.toString().toLocaleLowerCase()!="home"){
    this.sourceApp='VitalDx';
    let dbName = this.labAdminService.deploymentKey;
    await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async (session:any) => {
      this.storeSub =  this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
        let menuObj = result[result.length -1];
        this.storeSub.unsubscribe()
        if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
          this.userSessionDetails = session.userDetails
          await this.commonService.getCommmonTemplateData(menuObj,this.userSessionDetails);
          this.sourceApp='VitalDx';
          this.templateData=this.labAdminService.templateData;
          dbName = this.labAdminService.deploymentKey;
          sessionStorage.setItem('deploymentKey', dbName);
          sessionStorage.setItem('org_id', this.templateData.secondarykeys.OrganizationId);
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addDataToGrid(this.templateData.submenuData) : this.noDataFound = true;
          // this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
          this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Pathology Management','value':this.templateData['menuURL']}] })
          this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          this.getAuditableDetails(this.templateData.menuURL);
          this.GetButtonAccess(this.labAdminService.subMenuPermissions)
        }
       })
     })
    }
    else{
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addDataToGrid(this.templateData.submenuData) : this.noDataFound = true;
    this.getAuditableDetails(this.templateData.menuURL);
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
    this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
    //this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': "parentMenu", 'value': this.templateData.menuURL }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    this.getAuditableDetails(this.templateData.menuURL);
    }
  }
  get formcontrol() {
    return this.copyScreeningCommentsForm.controls;
  }
  getData(context?) {
    let queryVariable = {
      casetype: this.sourceApp !== 'VitalDx' ? this.templateData.secondarykeys.casetype?.toString() || null : this.selectedCaseType || null,
      orgid: sessionStorage.getItem('org_id')
    };
    let query = this.SubMenuCardModel.GetQuery('getOrganizationScreeningComments');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.templateData.submenuData = data.data.submenuData;
          if (context) {
            return
          }
          this.gridPage = true;

          this.templateData.submenuData.length > 0 ? this.addDataToGrid(this.templateData.submenuData) : this.showGrid = false;
          if (!this.templateData?.submenuData?.length) this.ScrngCommentsGridData = new CollectionView(...[]);
          this.addEditScreen = false;
        }
      }
    }, error => {

      console.error(error);
    });

  }

  addDataToGrid(Data: any) {
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Screening Comments') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }
    this.gridPage = true;
    this.gridheader = [
      'Id',
      'OrganizationID',
      'CaseType',
      'IsActive',
      'IsDefault',
      'Description',
      'TemplateName',
      'Sequence',
      'CreatedBy',
      'CreatedDate',
      'ModifiedBy',
      'ModifiedDate',
    ];
    this.gridheader.reverse();
    let gridarray = []
    let primary = {}
    this.ngxService.start();
    if (Data) {
      if (Data.length > 0) {
        for (let i = 0; i < Data.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(Data[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                if (key === 'Default' && value == "Yes") {
                  value = true;
                } else if (key === 'Default' && value == "No") {
                  value = false;
                }
                if (value == null) {
                  value = '';
                }
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
        // gridarray.sort((a, b) => a.Template_Name < b.Template_Name ? -1 : 1).sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
        gridarray.sort((a, b) => a.TemplateName.localeCompare(b.TemplateName))
      }
      if (gridarray.length > 0) {
        this.gridShowDiv = true;
      }
      else {
        this.ngxService.stop();
        this.gridShowDiv = false;
        this.noDataFound = true;
      }
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }
      this.ScrngCommentsGridData = new CollectionView(gridarray)
      this.showGrid = true
      this.ngxService.stop();
    }
    else {
      this.ngxService.stop();
      this.gridShowDiv = false;
      this.noDataFound = true;
    }
  }

  //#region Tooltip for the wijmo grid
  initGrid(grid, hitCount?) {
    // this.masterLevel ? grid.headersVisibility = wjcGrid.HeadersVisibility.Column : null
    if (hitCount) {
      if (!this.postUpload) {
        let selectedData = [];
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  refreshGrid(context?) {
    this.formcontrol['frmOrganization'].setErrors(null);
    if (this.copyDataClicked == true) {
      this.formGroupDirective.resetForm();
    }
    this.excelDataArray = [];
    this.selectedData = [];
    this.showInvalidColumns = false;
    this.copyScreeningCommentsForm.controls.frmOrganization.enable()
    this.copyDataClicked = false;
    this.bulkUpload = false;
    this.uploadClicked = false;
    this.postUpload = false;
    this.gridPage = true;
    this.addEditScreen = false;
    this.getData();
    this.resetFormErrors();
  }

  //#region  Function to cancel the data selection
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.selectedData = [];
    this.srcOrgid = '';
    this.searchInput = '';
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.postUpload = false;
    this.noDataFound = false;
    this.showDelete = false;
    this.checkStatus = false;
    let defDep = sessionStorage.getItem('deploymentKey')
    this.formcontrol['frmOrganization'].setErrors(null);
    this.copyScreeningCommentsForm.patchValue({
      frmDepKey: defDep.toUpperCase(),
      frmOrganization: ''
    })
    this.resetFormErrors();
    this.copyScreeningCommentsForm.controls.frmOrganization.enable()
  }

  //#region  Reset any error indication in the form
  resetFormErrors() {
    Object.keys(this.copyScreeningCommentsForm.controls).forEach(key => {
      this.copyScreeningCommentsForm.controls[key].setErrors(null)
    });
  }

  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory fields are missing' || value == 'already exists' || value == 'This record does not exists' || value == 'Failure'
          || value.toString().includes('Invalid') || value.toString().includes('accepts only') || value.toString().includes('Does not') || value.toString().includes('not belongs') || value == 'Id Missing') {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('successfully') || value == 'Success') {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString() == 'Ignored') || value == 'Screening comments already exists, will be created on approval' || value == 'Screening comments already exists, will be updated on approval') {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  loadCopyScreen() {
    if (this.copyBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    this.copyDataClicked = true
    this.gridPage = false;
    this.checkStatus = false;
    this.noDataFound = false;
    this.uploadClicked = false;
    this.gridWidth = 0;
    this.searchResult = [];
    this.searchInput = '';
    this.formcontrol['frmOrganization'].setErrors(null);
    this.copyScreeningCommentsForm.patchValue({
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
      frmOrganization: "",
      frmOrgID: "",
      frmcpyasactive: false
    })
    this.resetFormErrors()
    //this.disableOrgSearch = true;
    //this.destDeployment = sessionStorage.getItem('deploymentKey')
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys
    this.getListOrg();
  }

  onChangeDeployment(e) {
    this.copyScreeningCommentsForm.patchValue({
      frmDepKey: e.value,
      frmOrganization: "",
      frmOrgID: "",
      frmcpyasactive: false
    })
    this.gridWidth = 0
    this.searchInput = '';
    this.searchResult = [];
    this.postUpload = false;
    this.excelDataArray = []
    this.getListOrg();
    this.formcontrol['frmOrganization'].setErrors(null);
    this.copyScreeningCommentsForm.controls.frmOrganization.enable();
  }

  clearOrganization() {
    this.searchResult = []
    this.copyScreeningCommentsForm.patchValue({
      frmOrganization: ''
    })
    this.formcontrol['frmOrganization'].setErrors(null);
  }

  // async getListOrg() {
  //   let dbName = this.copyScreeningCommentsForm.value.frmDepKey;
  //   let queryVariable = {};
  //   let query = this.SubMenuCardModel.GetQuery("orgtype");
  //   let queryResult = this.commonService.GetCardRequest(queryVariable, query);
  //   this.ngxService.start();
  //   await this.vitalHttpServices.GetData(queryResult, dbName).toPromise().then(data => {
  //     this.formcontrol['frmOrganization'].setErrors(null);
  //     if (!data.errors) {
  //       //this.resetFormErrors();
  //       if (data) {
  //         if (data.data.submenuData && data.data.submenuData.length > 0) {
  //           this.organizationList = data.data.submenuData;
  //           this.formcontrol['frmOrganization'].setErrors(null);
  //         }
  //       }
  //     }
  //     this.ngxService.stop();
  //   }, error => {
  //     console.error(error);
  //     this.ngxService.stop();
  //   });
  // }
  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  async getListOrg() {
    let dbName = this.copyScreeningCommentsForm.value.frmDepKey;
    let query = this.GetQuery('adduserfilterorg');
    let queryString = null

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      this.formcontrol['frmOrganization'].setErrors(null);
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.organizationList = res.data.Organizations_list;
        this.formcontrol['frmOrganization'].setErrors(null);
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  fetchOrgSeries(value: string) {
    this.searchResult = []
    this.searchuserlist = [];
    this.searchUserInput = '';
    value = value?.toString().trim();
    this.selectedData = []
    this.copyScreeningCommentsForm.value.frmOrgID = ""
    if (!value) {
      this.searchuserlist = [];
      this.formcontrol['frmOrganization'].setErrors(null);
      this.searchResult = this.organizationList.filter(x => x.organizationname).sort((a, b) => a.organizationname?.toString().toLowerCase() < b.organizationname?.toString().toLowerCase() ? -1 : a.organizationname?.toString().toLowerCase() > b.organizationname?.toString().toLowerCase() ? 1 : 0)
      return (this.searchResult = this.searchResult.splice(0, 25));
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.organizationList.find(r => {
          if (r.organizationname) {
            if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
              this.srcOrgid = r.organizationid
              return r
            }
            else {
              this.srcOrgid = ''
              this.formcontrol['frmOrganization'].setErrors(null);
            }
          }
        })
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
        this.formcontrol['frmOrganization'].setErrors(null);
      }
    }
    else {
      this.organizationList.find(r => {
        if (r.organizationid.toString().toLowerCase() === value.toLowerCase()) {
          this.srcOrgid = r.organizationid
          return r
        }
        else {
          this.srcOrgid = ''
          this.formcontrol['frmOrganization'].setErrors(null);
        }
      })
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
      this.formcontrol['frmOrganization'].setErrors(null);
    }
    this.resetFormErrors();
  }
  //
  selectedCopyDataOrganization(event, data) {
    this.selectedData = [];
    if (event.source.selected) {
      this.searchInput = data.organizationname;
      this.srcOrgid = data.organizationid;
      this.copyScreeningCommentsForm.patchValue({
        frmOrgID: data.organizationid,
        frmOrganization: data.organizationname
      })
      this.formcontrol['frmOrganization'].setErrors(null);
    }
    this.getDataToCopy();
  }

  getDataToCopy() {
    this.postUpload = false;
    if (typeof (this.copyScreeningCommentsForm.value.frmOrgID) == 'object') {
      let frmorgid: any = this.copyScreeningCommentsForm.value.frmOrganization
      this.copyScreeningCommentsForm.value.frmOrgID = frmorgid[0].organizationid
    }
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.copyScreeningCommentsForm.value.frmOrgID.toString() };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationScreeningCommentsCopy");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.copyScreeningCommentsForm.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      this.formcontrol['frmOrganization'].setErrors(null);
      if (!data.errors) {
        let copyData = data.data.submenuData;
        if (copyData.length == 0) {
          this.gridWidth = 0;
          this.noDataFound = true;
          return
        }
        this.noDataFound = false;
        this.copyDataProcess(copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }
  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        if (key.match(/name/i)) {
          primary['TemplateName'] = value
        }
        else if (key.match(/Description/i)) {
          primary['Description'] = value
        }
        else if (key.match(/Active/i)) {
          primary['IsActive'] = value == 'Yes' ? true : false
        }
        else if (key.match(/Default/i)) {
          primary['isDefault'] = value == 'Yes' ? 1 : 0
        }
        else if (key.match(/Sequence/i)) {
          primary['Sequence'] = value;
        }
        delete copyData[i]['status']
      }
      dataForValidation.push(primary);
    }
    this.convertToJson("", true, dataForValidation);
  }

  //#region Open upload screen with prerequisite data fetch
  loadUploadScreen() {
    if (this.uploadBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    this.uploadClicked = true;
    this.gridPage = false;
    // this.getTemplates()
    this.bulkUpload = true;
    this.gridWidth = 0
    let queryVariable = { tablename: 'OrganizationCommentsTemplates', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          for (let item of data.data.allFields) {
            if (!item.Column.match(/testCategory/i)
              && !item.Column.match(/Type/i)
              && !item.Column.match(/OrganizationID/i)
              && !item.Column.match(/caseType/i)
              && !item.Column.match(/UserId/i)
              && !item.Column.match(/Sequence/i)
              && !item.Column.match(/CreatedBy/i)
              && !item.Column.match(/CreatedDate/i)
              && !item.Column.match(/ModifiedBy/i)
              && !item.Column.match(/ModifiedDate/i)
              && !item.Column.match(/SiteId/i)
              && !item.Column.match(/IsUserlevelTemplates/i)
              && !item.Column.match(/IsUserLevelCaseCommentsTemplateEnabled/i)
              && !item.Column.match(/ReferenceTemplateId/i)
              && !item.Column.match(/OrganizationCommentsTemplateGUID/i)
              && !item.Column.match(/DivPlaceHolder/i)
              && !item.Column.match(/AdminKey/i)) {
              this.allFields[0][item.Column] = '';
            }
            if (!item.Column.match(/testCategory/i)
              && !item.Column.match(/Type/i)
              && !item.Column.match(/OrganizationID/i)
              && !item.Column.match(/caseType/i)
              && !item.Column.match(/Sequence/i)
              && !item.Column.match(/UserId/i)
              && !item.Column.match(/CreatedBy/i)
              && !item.Column.match(/CreatedDate/i)
              && !item.Column.match(/ModifiedBy/i)
              && !item.Column.match(/ModifiedDate/i)
              && !item.Column.match(/SiteId/i)
              && !item.Column.match(/IsUserlevelTemplates/i)
              && !item.Column.match(/IsUserLevelCaseCommentsTemplateEnabled/i)
              && !item.Column.match(/ReferenceTemplateId/i)
              && !item.Column.match(/OrganizationCommentsTemplateGUID/i)
              && !item.Column.match(/AdminKey/i)
              && !item.Column.match(/DivPlaceHolder/i)
              && !item.Column.match(/SourceGUID/i)) {
              this.BulkUpdate[0][item.Column] = '';
            }
            this.BulkUpdate[0]["ID"] = '';
          }
        }
      }
    }, error => {

      console.error(error);
    });

  }
  //#endregion
  onFileChange(ev) {
    this.bulkUpdateFile = false
    this.UpdateColumnMisMatch = false
    this.missingColumn = []
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if(filename.toLowerCase().includes('bulk update')){
     this.bulkUpdateFile = true
    }
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'ScreeningComments')
            validSheet.length > 0 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0 || tempExcelArr[0].casetype != null) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    
    if(Object.keys(tempExcelArr[0]).length < 2){
      this._snackbar.open("Please Provide Valid Data", "close")
      return;
     }

    this.uploadedHeaders = Object.keys(tempExcelArr[0]);
    this.expectedHeaders = this.bulkUpdateFile ? Object.keys(this.BulkUpdate[0]) : Object.keys(this.allFields[0]);
    if(this.bulkUpdateFile){
        // Check if arrays are of equal length
        if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
          this.UpdateColumnMisMatch = true;
       }
       this.missingColumn = this.expectedHeaders.filter(header => !this.uploadedHeaders.includes(header))
    }else if(!this.bulkUpdateFile && !copyfrmorg){
      if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
        this._snackbar.open("Invalid excel", "Close") 
        return;
      }
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        if (this.copyDataClicked) {
          delete tempExcelArr[i]['Sequence'];
        }
        // const isActive = tempExcelArr[i].IsActive;

        // if (isActive === 0 || isActive === 'false' || isActive === false) {
        //   tempExcelArr[i].IsActive = 0;
        // } else {
        //   tempExcelArr[i].IsActive = (isActive === '' || isActive === null || isActive === 1 || isActive === 'true' || isActive == true) ? 1 : 0;
        // }

        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if ((key.toString().toLowerCase() == 'templatename' || key.toString().toLowerCase() == 'description')) {
              if(value){
                  value = value.toString().trim();
                }else if( value == null && value == undefined){
                  value = null;
                }else if(value == ''){
                  value = '';
                }
            }
            if(key.toString().toLowerCase()== 'isactive'){
              if(value === 0 || value === 'false' || value === false){
               value = 0;
              }else if(value === '' || value === null || value === 1 || value === 'true' || value == true){
               value = 1
              }else{
                0
              }   
            }                
                
            if (key.toString().toLowerCase() == 'id') {
              value = value == '' || value == null || value == undefined ? null : value           
             }
            if (key.toString().toLowerCase() == 'isdefault') {
              value = value == null || value == '' ? 0 : value
            }
            primary[key] = value;
           }
          }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;

    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    // Add the key and value to the primary object in tempArray
    if(this.UpdateColumnMisMatch){
     for(let i=0; i<tempExcelArr.length; i++){
      for(let j=0; j<this.missingColumn.length; j++){
        tempExcelArr[i][this.missingColumn[j]] = '';
      }
     }    
    }

    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find((d): any => {
      Object.assign(d, { slno: i, notes: '', tablename: 'OrganizationCommentsTemplates', organizationid: this.templateData.secondarykeys.OrganizationId.toString(), casetype: this.templateData.cardtype.toString(), createdby: (sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '') ? -100 : sessionStorage.getItem("Userid") });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }

  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }


  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if (result) {
      this.convertToJson(result)
    }
  }
  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.validateScreeningCommentsData(dataArray).subscribe(result => {
      if (!result.errors) {
        if (result.length > 0) {
          this.ngxService.stop();
          for (let i = 0; i < dataArray.length; i++) {
            if (!this.copyDataClicked) {
              if (this.bulkUpdateFile && this.missingColumn.some(e=> e.toLowerCase() == 'id')) {
                this._snackbar.open("Please provide valid data", 'Failed');
                this.ngxService.stop();
                return;
              }
            }
          }
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }

          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddGridData(dataArray, 1);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
    });
    this.ngxService.stop();
  }

  AddGridData(data, hitCount) {
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["status"] || this.excelDataArray[i]["status"] == '') {
        this.excelDataArray[i]["status"] = 'Ignored'
      }
    }
    this.noDataFound = false
    this.copyFlag = null;
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (value && value.toString().match(/null/i)) {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }
              if ((key == 'templatename' || key == 'description')) {
                if(value){
                  value = value
                }
                else if(this.UpdateColumnMisMatch && this.missingColumn.length > 0){
                    if(this.missingColumn.find(e=> e.toLowerCase() == key.toString().toLowerCase())){
                     value = '';
                    }else{
                      value = ''
                      primary['notes'] = 'Mandatory fields are missing'
                    }
                } 
                else{
                   value = ''
                   primary['notes'] = 'Mandatory fields are missing'
                }
              }
              if(key == 'id' && (!value || (value && value.toString().toLowerCase().trim() == ''))  && !this.copyDataClicked){
                  value = ''
                  primary['notes'] = 'Id Missing'
              }
            }
            if (key.toString().toLowerCase() == 'Sequence') {
              if ((!value || value.toString() == '')) {
                value = 'Not Specified';
              }
              else {
                value = value;
              }
            }

            if (this.copyDataClicked) {
              if (key == 'isactive') {
                value = true ? 'Yes' : 'No'
              }
              if (this.checkStatus == true && key == 'isactive') {
                value = 'No'
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }

        gridArray.sort((a, b) => a.sequence < b.sequence ? -1 : 1)
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.gridData = new CollectionView(gridArray);
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
    }
    this.ngxService.stop();
  }

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
  }

  approveScrComments(copyData?) {
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i) && !this.excelDataArray[i]["notes"].toString().match(/already exists/i)) {
            errorcount++
          }
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (!this.bulkUpdateFile && (value === "null" || value === "" || value === null)) {
              this.excelDataArray[i][key] = null;
            }	else if(this.bulkUpdateFile){
              if(value === ""){
                this.excelDataArray[i][key] = ''
              }else if(value === null || value === "null"){
                this.excelDataArray[i][key] = null
              }else{
                this.excelDataArray[i][key] = value.toString();
              }
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }

            if (key.toString().toLowerCase() === 'isactive') {
              if (value == 0 || value === 'false') {
                value = "0"
              }
              else if ( value == null || value == 1 || value === 'true') {
                value = "1"
              } else if(value === ''){
                value = this.bulkUpdateFile ? '' : "1";
              }
              if(this.bulkUpdateFile){
                this.excelDataArray[i]["modifiedby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
              }else if(!this.bulkUpdateFile && !this.copyDataClicked){
                this.excelDataArray[i]["createdby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
              }      
            }      
            if (this.copyDataClicked) {
              if (key == 'isactive') {
                value = 'Yes' ? true : false
              }
            }
          }
        }
      }

      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        selectedData.push(this.selectedData[i]._data);
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.selectedCopyData[i]['sequence'] = null;
          this.selectedCopyData[i]['isactive'] = 'Yes' ? true : false
          if (this.checkStatus == true) {
            this.selectedCopyData[i]['isactive'] = false;
          }
          this.selectedCopyData[i]['createdby'] = (sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '') ? -100 : sessionStorage.getItem("Userid")
        }
        this.excelDataArray = this.selectedCopyData
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      //copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected screening comments already exists", "Close") : null : null
      let sendjson = dataArray;
      let context = copyData ? null : ''
      sendjson.length > 0 ? sendjson.some(va => va.id) ? this.editMethod(sendjson, true) : this.addScreeningmethod(sendjson, true, context) : this._snackbar.open('screening comments already exists', 'Close')
    }
  }

  //#region
  addScreeningmethod(dataArray: object[], bulkUpload: boolean = false, context?) {
    this.ngxService.start();
    this.vitalHttpServices.InsertScreeningComments(dataArray).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        //this.copyRevisionReasonsForm.controls.frmOrganization.disable()
        if (result.length > 0) {
          if (this.copyDataClicked) {
            this.commonService.auditDetails('', '', [], dataArray, 'Copy', this.templateData, this.auditableColumns);
          }
          else {
            this.commonService.auditDetails('', '', [], dataArray, 'Upload', this.templateData, this.auditableColumns);
          }
          //this.checkStatus = false;
          this.postUpload = true;
          this.showDelete = false;
          // this.postDownload = true;
          this.showDelete = false;
          if (this.copyDataClicked && this.postUpload) {
            this.copyScreeningCommentsForm.controls.frmOrganization.disable();
          }
          this.masterLevel = false;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == uploadresult[j].slno) {
                this.excelDataArray[i]['status'] = uploadresult[j].message.toString().match(/Created successfully/i) ? 'Success' : 'Ignored';
                this.excelDataArray[i]['notes'] = uploadresult[j].message;
                this.excelDataArray[i]['sequence'] = uploadresult[j].sequence;
                this.excelDataArray[i]['ID'] = uploadresult[j].ID;
                this.excelDataArray[i]['isdefault'] = uploadresult[j].isdefault == 1 ? 'true' : 'false'
                if (this.copyDataClicked) {
                  this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 'No' : 'yes') : 'Yes'
                } else {
                  this.excelDataArray[i]["isactive"] == "1" ? 'Yes' : 'No'
                }
                // this.auditDetails([], result[j], this.copyFlag ? 'Copied' : 'Add');
              }
            }
          }
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          this.sheetHeader.push('Status');
          this.AddGridData(this.excelDataArray, 0);

          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.templateData.menuURL.toString().trim() + ' already exist!', 'Close') }
            else { this._snackbar.open('Screening comments already exists!', 'Close'); }
            return;
          }
          else {
            let message = bulkUpload ? 'Data uploaded successfully' : this.copyFlag ? 'Screening comments copied successfully' : 'Screening comments created successfully';
            this._snackbar.open(message, 'Close');
          }
          if (!this.bulkUpload) {
            if (context == '') {
              this.getInputData('', this.templateData.submenuData);
            }
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //#endregion

  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data['slno'];
      delete e._data['tablename'];
      delete e._data['ID'];
      delete e._data['createdby'];
      delete e._data['modifiedby'];
      delete e._data['sequence'];
      excel.push(e._data);
    });
    let filename = 'Screening Comments_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationCommentsTemplates');
    XLSX.writeFile(wb, filename);
  }

  downloadTemplate(allFieldsCheck: boolean = false) {
    let filename = ""
    let sample
    if (allFieldsCheck) {
      filename = 'Screening Comments_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      sample = true
    }
    else {
      filename = 'Screening Comments_' + 'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    }
    var ws = XLSX.utils.json_to_sheet(allFieldsCheck ? this.allFields : this.mandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Screening Comments');
    sample ? ws = XLSX.utils.json_to_sheet(this.sampleData) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    XLSX.writeFile(wb, filename);
  }

  //Download Bulk Update
  downloadBulkUpdate() {

    let filename = 'Screening Comments_' + 'Bulk Update_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.BulkUpdate);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Screening Comments');
    ws = XLSX.utils.json_to_sheet(this.sampleDataUpdate)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }
  //Add and Edit Part
  getInputData(action, data) {
    if (this.createBtn || this.editBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    if (action.toLowerCase() == 'edit') {
      this.action = "Edit Screening Comments";
      this.currentData = data
      this.ScreeningCommentsForm.patchValue({
        frmname: this.currentData.Template_Name,
        frmdesc: this.currentData.Description,
        frmsequence: this.currentData.Sequence,
        frmIsdefault: this.currentData.Default,
        frmIsActive: this.currentData.Active,
        // frmIsActive: this.currentData.Active == 'Yes' ? true : false,
        // frmIsdefault: this.currentData.Default == 'Yes' ? true : false
      })
    }
    else {
      // this.editClicked=true
      this.ScreeningCommentsForm.reset();
      this.action = "Create Screening Comments";
      this.ScreeningCommentsForm.patchValue({
        frmname: "",
        frmdesc: "",
        frmsequence: "",
        frmIsActive: true,
        frmIsdefault: false
      })
      Object.keys(this.ScreeningCommentsForm.controls).forEach(key => {
        this.ScreeningCommentsForm.controls[key].setErrors(null)
      });
    }
    this.gridPage = false;
    this.addEditScreen = true;
  }
  openEditScreen(grid, event?) {
    if (this.editBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    // var hti = grid.hitTest(event);
    // let selectedrowData = hti.panel.rows[hti.row].dataItem;
    let selectedrowData = grid;
    // this.selectedrowData = selectedrowData;
    this.getInputData('edit', selectedrowData);
    this.rowId = selectedrowData.Id
  }

  saveScreeningComments(action, data) {
    if (this.action.toLowerCase().includes('edit')) {
      this.editScreeningComments(data)
    }
    else {
      this.createScreeningComments(action, data);
    }
  }

  //edit
  editScreeningComments(data) {
    const { frmname, frmdesc, frmsequence, frmIsActive, frmIsdefault } = data;
    if (!frmname.toString().trim() || !frmdesc.toString().trim()) {
      this._snackbar.open('Please enter the required fields!', 'Close')
      return
    }
    else if (frmsequence && /^0*$/.test(frmsequence)) { this._snackbar.open('Zero is not allowed for Sequence', 'Close'); return }
    let saveData = {
      id: this.rowId,
      organizationid: this.templateData.secondarykeys.OrganizationId,
      casetype: this.templateData.secondarykeys.casetype.toString(),
      type: "Screening Comments",
      templatename: frmname,
      description: frmdesc,
      sequence: frmsequence,
      isactive: frmIsActive,
      isdefault: frmIsdefault,
      modifiedby: (sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '') ? -100 : sessionStorage.getItem("Userid")
    }
    if (this.templateData.submenuData.some((va: any) =>
      va.Template_Name && va.Template_Name.toString().toLowerCase().trim() == frmname.toString().toLowerCase().trim() && va.Description && va.Description.toString().toLowerCase().trim() == frmdesc.toString().toLowerCase().trim() && va.Id != this.rowId)) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Screening Comments", message: "Template with the same name and description already exists. Do you still want to proceed with updating this template?", alert: "", continue: "Yes", cancel: "No" }
      });
      return dialogRef
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result) {
            this.editMethod([saveData]);
          }
        })
    }
    else {
      this.editMethod([saveData]);
    }
  }

  editMethod(saveData, bulkUpdate?: boolean) {
    this.ngxService.start();
    this.vitalHttpServices.editScreeningComments(saveData).subscribe(response => {
      if (response[0].status == "Success") {
        this._snackbar.open("Updated successfully", "Close")
        if (!bulkUpdate) {
          // this.auditDetails(this.currentData, saveData, 'Edit');
        }
        else {
          // this.auditDetails(saveData, saveData, 'Edit');

        }
        if (!bulkUpdate) { this.refreshGrid(); }
        else {
          if (response.length > 0) {
            this.commonService.auditDetails('', '', [], saveData, 'Bulk Update', this.templateData, this.auditableColumns);
            this.postUpload = true;
            this.showDelete = false;
            this.showDelete = false;
            this.masterLevel = false;
            let uploadresult = this.convertObjKeysToLower(response);
            for (let i = 0; i < this.excelDataArray.length; i++) {
              for (let j = 0; j < uploadresult.length; j++) {
                if (this.excelDataArray[i]['slno'] == uploadresult[j].slno) {
                  this.excelDataArray[i]['status'] = uploadresult[j].message.toString().match(/updated successfully/i) ? 'Success' : 'Ignored'
                  this.excelDataArray[i]['notes'] = uploadresult[j].message
                  //this.excelDataArray[i]['iactive'] = uploadresult[j].isactive
                  // this.excelDataArray[i]['isdefault'] = uploadresult[j].isdefault ? "1" : "0"
                }
              }
            }
            let dataExistsCount = 0;
            for (let i = 0; i < response.length; i++) {
              if (Object.values(response).every(function (item: any) {
                return item.Status == "Ignored" ? true : false
              })) {
                dataExistsCount++;
              }
            }
            this.sheetHeader.push('Status');
            this.AddGridData(this.excelDataArray, 0);

            if (dataExistsCount == response.length) {
              this._snackbar.open('Screening comments already exist', 'Close');
              return;
            }
            else {
              let message = 'Data updated successfully';
              this._snackbar.open(message, 'Close');
            }
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
          }
        }
      } else {
        this._snackbar.open("Update failed!", "Close")
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })

  }

  //create
  createScreeningComments(action, data) {
    const { frmname, frmdesc, frmsequence, frmIsActive, frmIsdefault } = data;
    if (!frmname.toString().trim() || !frmdesc.toString().trim()) {
      this._snackbar.open('Please enter the required fields!', 'Close')
      return
    }
    else if (frmsequence && /^0*$/.test(frmsequence)) { this._snackbar.open('Zero is not allowed for Sequence', 'Close'); return }
    let saveData = {
      id: this.rowId,
      organizationid: this.templateData.secondarykeys.OrganizationId,
      casetype: this.templateData.secondarykeys.casetype.toString(),
      type: "Screening Comments",
      templatename: frmname,
      description: frmdesc,
      sequence: frmsequence,
      isactive: frmIsActive,
      isdefault: frmIsdefault,
      createdby: (sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '') ? -100 : sessionStorage.getItem("Userid")
    }
    if (this.templateData.submenuData && this.templateData.submenuData.some((va: any) =>
      va.Template_Name.toString().toLowerCase().trim() == frmname.toString().toLowerCase().trim() && va.Description.toString().toLowerCase().trim() == frmdesc.toString().toLowerCase().trim())) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Screening Comments", message: "Template with the same name and description already exists. Do you still want to proceed creating a duplicate template?", alert: "", continue: "Yes", cancel: "No" }
      });
      return dialogRef
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result) {
            this.InsertScreeningComments(saveData, action);
          }
        })
    }
    else {
      this.InsertScreeningComments(saveData, action);
    }
    // .catch(error => { this._snackbar.open("An error occurred while processing your request", "Failed"); console.error(error) })
    // this.ngxService.stop();
  }

  InsertScreeningComments(saveData, action) {
    this.ngxService.start();
    this.vitalHttpServices.InsertScreeningComments([saveData]).subscribe(response => {
      this._snackbar.open("Screening comments created successfully", "Close")
      if (action == 'close') {
        this.refreshGrid();
      }
      else {
        this.ScreeningCommentsForm.reset();
        this.getData('create')
        this.ScreeningCommentsForm.patchValue(
          {
            frmIsActive: true,
            frmIsdefault: false,
            frmname :"",
            frmdesc:""
          }
        )
      }
      // this.auditDetails('', response, 'create')
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }
  //End region of Add and Edit Part
  exportScreeningCommentsList(grid) {
    if (this.exportBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    let excel = [];
    let modifiedExcel: any = [];
    // const view: any = this.gridRef.collectionView;
    // let oldPgSize = view.pageSize
    // view.pageSize = 0;
    // this.gridRef.beginUpdate()
    // let rows = this.gridRef.rows
    let rows = grid._pgView
    rows.find((e: any): any => {
      excel.push(e);
    });

    for (let i = 0; i < excel.length; i++) {
      modifiedExcel[i] = {}
      modifiedExcel[i]["Id"] = excel[i]["Id"]
      modifiedExcel[i]["OrganizationID"] = excel[i]["OrganizationID"]
      modifiedExcel[i]["CaseType"] = excel[i]["CaseType"]
      modifiedExcel[i]["Sequence"] = excel[i]["Sequence"]
      modifiedExcel[i]["Type"] = "Screening Comments "
      modifiedExcel[i]["Template Name"] = excel[i]["TemplateName"]
      modifiedExcel[i]["Description"] = excel[i]["Description"]
      modifiedExcel[i]["Default"] = excel[i]["Default"]
      modifiedExcel[i]["Active"] = excel[i]["Active"]
      modifiedExcel[i]["CreatedBy"] = excel[i]["CreatedBy"]
      modifiedExcel[i]["CreatedDate"] = excel[i]["CreatedDate"]
      modifiedExcel[i]["ModifiedBy"] = excel[i]["ModifiedBy"]
      modifiedExcel[i]["ModifiedDate"] = excel[i]["ModifiedDate"]
    }
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Screening Comments_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(modifiedExcel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + "OrganizationCommentsTemplates");
    XLSX.writeFile(wb, filename);
    // view.pageSize = oldPgSize;
    // this.gridRef.endUpdate()
  }



  // auditDetails(oldObj, newObjArray, value) {
  //   // let oldObject = {}
  //   if (value.match(/edit/i)) {
  //     this.ngxService.start();
  //     this.commonService.createActivityTracker(value, oldObj.ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[0], oldObj);
  //     this.ngxService.stop();
  //   } else if (value.match(/delete/i)) {
  //     newObjArray.id.forEach(va => {
  //       this.commonService.createActivityTracker(value, va, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray, {});
  //     })
  //   }
  //   else {
  //     //new record
  //     this.ngxService.start();
  //     this.commonService.createActivityTracker(value, newObjArray.ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray, {});
  //     this.ngxService.stop();
  //   }
  // }
  changeplan() {
    if (this.ScreeningCommentsForm.value.frmIsActive == false) {
      this.ScreeningCommentsForm.patchValue(
        {
          frmIsdefault: false
        }
      )
    }
  }
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labAdminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Copy":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "DragnDrop":
          this.dragnDrop = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deletebtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }

  newDeleteMethod(deleteObj: any) {
    this.deleteScreeningComments(deleteObj.event, deleteObj.rowData)
  }

  recieveObjFromListing(object: any) {
    if (object.newData.Action == 'Create') {
      delete object.newData.ModifiedBy
      delete object.newData.Action
      object.newData = { ...object.newData, ...{ 'type': 'Screening Comments' } }
      const newObj = {};
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.InsertScreeningCommentsTemplate(newObj);
    }
    else {
      delete object.newData.Action
      delete object.newData.CreatedBy
      object.newData['Id'] = object.oldData['Id'];
      const newObj = {};
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.EditScreeningCommentsTemplate(newObj, object.oldData);
    }
  }

  InsertScreeningCommentsTemplate(saveData) {
    this.ngxService.start();
    this.vitalHttpServices.InsertScreeningComments([saveData]).subscribe(response => {
      if (response[0].status == "Success") {
        this.selectedCaseType = '';
        this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
        saveData.isactive = saveData.isactive == 1 ? 'Active' : 'Inactive'
        saveData.isdefault = saveData.isdefault == 1 ? 'Yes' : 'No'
        delete saveData.sequence
        this.commonService.auditDetails('ID', 'templatename', response, [saveData], 'Create', this.templateData, this.auditableColumns);
        this._snackbar.open("Screening comments created successfully", "Close");
        this.refreshGrid();
        this.ngxService.stop();
      }
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }

  EditScreeningCommentsTemplate(saveData, oldData) {
    this.ngxService.start();
    this.vitalHttpServices.editScreeningComments([saveData]).subscribe(response => {
      if (response[0].status == "Success") {
        this.selectedCaseType = '';
        this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
        saveData.isactive = saveData.isactive == 1 ? 'Active' : 'Inactive'
        saveData.isdefault = saveData.isdefault == 1 ? 'Yes' : 'No'
        this.commonService.auditDetails('Id', 'TemplateName', [oldData], [saveData], 'Edit', this.templateData, this.auditableColumns);
        this._snackbar.open("Screening comments updated successfully", "Close")
        this.refreshGrid();
        this.ngxService.stop();
      }
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }

  deleteScreeningComments(flex?, rowData?): any {
    if (this.deletebtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
          autoFocus: false,
      width: '400px',
      panelClass: 'admin-custom-popup',
      // data: { header: "Action", message: "Clicking on yes will delete the selected row.", alert: "Do you wish to continue?", continue: "yes", cancel: "no" }
      data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
    })
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        let obj = {}
        if (!(flex && rowData)) {
          let idArray = []
          this.selectedData.forEach(element => {
            idArray.push(element._data.Id)
          });
          obj = { id: idArray }
        }
        else {
          obj = { id: [rowData.Id] }
        }
        this.ngxService.start();
        //this.rowId=rowData.dataItem.Id;
        this.vitalHttpServices.deletescreeningComments(obj, sessionStorage.getItem("DeploymentKey")).subscribe(data => {
          this.ngxService.stop();
          this.getData();
          this.selectedData = [];
          this.selectedCaseType = '';
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          this._snackbar.open('Deleted successfully!', "Close");
          this.commonService.auditDetails('Id', 'TemplateName', [rowData], [], 'Delete', this.templateData, this.auditableColumns);
          // this.auditDetails(this.currentData, obj, 'delete');
          this.refreshGrid();
          // (<wjcCore.CollectionView>flex.collectionView).remove(rowData.dataItem);
          this.selectedData.forEach(element => {
            // (<wjcCore.CollectionView>flex.collectionView).remove(element._data);
          });
          this.templateData.submenuData = this.templateData.submenuData.filter(va => va.Id != rowData.Id);
          // if (flex.rows.length == 0) {
          //   this.showGrid = false;
          //   this.noDataFound = true;
          //   this.uploadClicked = false;
          // }
        }, error => {
          console.error(error)
          this.ngxService.stop();
        });
      }
    })
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  selectedCasetype(event)
  {
    this.templateData.secondarykeys.casetype = event?.CaseType?.object?.CaseType != 'All Case Types' ? event?.CaseType?.object?.CaseType : "";
    this.selectedCaseType = event?.CaseType?.object?.CaseType != 'All Case Types' ? event?.CaseType?.object?.CaseType : "";
    this.refreshGrid();
  }

  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copyScreeningCommentsForm, controlName ,this.copyScreeningCommentsForm.value.frmOrganization)
  }
}


class ColDef {
  constructor(public binding?: string, public header?: string) { }
}

