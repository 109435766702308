<div *ngIf="gridDisplay" class="diagon-header">
    <div class="modal-header admin-model-header mb-2 mt-3 ">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>
                <div *ngIf="!OrgLevelDisplay">{{headerName}}</div>
                <div *ngIf="OrgLevelDisplay">{{headerName}}</div>
            </strong>
        </h3>
    </div>
    <form [formGroup]="frm_testForm" autocomplete="off">

        <div *ngIf="showtemplated==false" class="button-field-wrap mt-2">
            <mat-form-field appearance="outline" class="frmMatSelect w-25">
                <mat-label>
                    Templates
                </mat-label>
                <input type="text" #txtInput matInput [(ngModel)]="selectedOption" formControlName="frm_panel"
                    style="width: 95%;" (input)="filterUsers(frm_testForm.value.frm_panel)" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngIf="showOrgInDrop" (click)="this.inData = '';AddGridData()" value="Group Templates"
                        class="mat-opt-align">
                        <span>Group Templates</span>
                    </mat-option>
                    <mat-option *ngFor="let item of userNamesList" class="mat-opt-align"
                        (onSelectionChange)="selectedUserGridData($event, item)"
                        value="{{item.username}} ({{item.rolename}})" title="{{item.username}} ({{item.rolename}})">
                        <span>{{item.username}} ({{item.rolename}})</span>
                    </mat-option>
                </mat-autocomplete>
                <button matSuffix mat-icon-button>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <button *ngIf="selectedOption" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                    (click)="fnToggleFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div *ngIf="showtemplated==false" class="button-field-wrap p-0 m-0 row col-sm-12">
            <div class="column instr-size mb-1 col-sm-12 pr-0 info-wrap">
                <img aria-hidden="true" src="/assets/icons/help.svg" alt="help" width="15px" height="auto"
                    class="icon-align" />
                <span class="instr-size mb-1 col-sm-3 pr-0">
                    You can select the group or user level templates to view the details in the Grid.
                </span>
            </div>
        </div>
    </form>
    <div *ngIf="userCardFlag" class="row button-wrap">
        <mat-form-field appearance="outline" class="col-sm-3">
            <mat-label>Role</mat-label>
            <mat-select disableOptionCentering [(ngModel)]="selectedRole">
                <mat-option (onSelectionChange)="setUserRole($event,user)" value="{{user.RoleName}}"
                    *ngFor="let user of userRoles">
                    {{user.RoleName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="templateData && templateData.labels && gridwidth > 0" class="modal-content bordernone">
        <wj-flex-grid [headersVisibility]="'Column'" class="mt-2" (initialized)="initGrid(diagtemplategrid)"
            [autoSizeRows]="false" [itemsSource]="gridData" [itemFormatter]="formatItem" #diagtemplategrid>

            <wj-flex-grid-column [width]="170" [format]="'d'" [visible]="'!Template ID'" [header]="'Template ID'"
                [binding]="'Org_Diag_Template_ID'" [isReadOnly]="true"> </wj-flex-grid-column>
                <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Diagnosis'"
                [binding]="'Diagnosis'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
                    <div class="divwordwrap" style="word-break: break-word;">
                        {{cell.item.Diagnosis}}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
                    <div class="tadiv">
                        <input class="tadiv" maxlength="125" [disabled]="hideEditBtn" [(ngModel)]="cell.value" />
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Diagnosis Codes'"
                [binding]="'Diagnosis_Code'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
                    <div class="divwordwrap" style="word-break: break-word;">
                        {{cell.item.Diagnosis_Code}}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
                    <div class="tadiv">
                        <input class="tadiv" maxlength="125" [disabled]="hideEditBtn" [(ngModel)]="cell.value" />
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Microscopic Notes'"
                [binding]="'MicroscopicNotes'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
                    <div class="divwordwrap" style="word-break: break-word;">
                        {{cell.item.MicroscopicNotes}}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
                    <div class="tadiv">
                        <input class="tadiv" maxlength="125" [disabled]="hideEditBtn" [(ngModel)]="cell.value" />
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Intrepretation'"
                [binding]="'Intrepretation'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
                    <div class="divwordwrap" style="word-break: break-word;">
                        {{cell.item.Intrepretation}}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
                    <div class="tadiv">
                        <input class="tadiv" maxlength="125" [disabled]="hideEditBtn" [(ngModel)]="cell.value" />
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Display Color'"
                [binding]="'DisplayColor'" [dataMap]="Flagdisplaycolor">
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Diagnosis Tags'"
                [binding]="'DiagnosisTags'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
                    <div class="divwordwrap" style="word-break: break-word;">
                        {{cell.item.DiagnosisTags}}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
                    <div class="tadiv">
                        <input class="tadiv" maxlength="125" [disabled]="hideEditBtn" [(ngModel)]="cell.value" />
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Is Billable'"
                [binding]="'NotBillable'" [dataMap]="Statusvalue">
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Hidden'" [binding]="'ishidden'"
                [dataMap]="Statusvalue">
            </wj-flex-grid-column>
            <!-- <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'ICD Codes'" [binding]="'ICD_Codes'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
                    <div class="divwordwrap" style="word-break: break-word;">
                        {{cell.item.ICD_Codes}}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
                    <div class="tadiv">
                        <input class="tadiv" maxlength="125" [disabled]="hideEditBtn" [(ngModel)]="cell.value" />
                    </div>
                </ng-template>
            </wj-flex-grid-column> -->
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Report to Cancer Registry'"
                [binding]="'Report_To_Cancer_Registry_Settings'" [dataMap]="ReportToCancerRegistrySettingsvalue">
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Report to Registry'"
                [binding]="'Reporttoregistry'" [dataMap]="Reporttoregistryvalue">

            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [binding]="'Abnormal'"
                [dataMap]="Abnormalvalue">
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Severity Order'"
                [binding]="'Severity_Order'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
                    <div class="divwordwrap" style="word-break: break-word;">
                        {{cell.item.Severity_Order}}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
                    <div class="tadiv">
                        <input class="tadiv" maxlength="125" [disabled]="hideEditBtn" [(ngModel)]="cell.value" />
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Site Name'" [binding]="'Site_Name'"
                [isReadOnly]="true"> </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [disabled]="hideEditBtn" [header]="'Case Type'" [binding]="'Case_Type'"
                [isReadOnly]="true"> </wj-flex-grid-column>

                <wj-flex-grid-column [width]="170" [visible]="false" [header]="'Created By'" [binding]="'CreatedBy'"
                    [isReadOnly]="true"> </wj-flex-grid-column>
                <wj-flex-grid-column [width]="170" [visible]="false" [header]="'Created Date'" [binding]="'CreatedDate'"
                [isReadOnly]="true"> </wj-flex-grid-column>
            <wj-flex-grid-column [width]="170" [visible]="false" [header]="'Modified By'" [binding]="'ModifiedBy'"
                [isReadOnly]="true"> </wj-flex-grid-column>
                <wj-flex-grid-column [width]="170" [visible]="false" [header]="'Modified Date'" [binding]="'ModifiedDate'"
                [isReadOnly]="true"> </wj-flex-grid-column>

            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <br />
        <div class="align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>
        <div class="alignText mb-2">
            <br />
            <div class="sub-button">
                <button (click)="ExportExcel(diagtemplategrid)" mat-raised-button class="admin-btn-success mr-4"
                    [disabled]="hideExportBtn">Export</button>

                <button (click)="UploadMethod()" mat-raised-button class="admin-btn-success mr-4"
                    [disabled]="hideuploadBtn">Upload</button>

                <button (click)="copyOrgData()" mat-raised-button class="admin-btn-success"
                    title="Copy from other entity" [disabled]="hideCopyToOrgBtn">Copy</button>
            </div>
        </div>
    </div>
    <div class="container create-btn col-sm-12 mt-4 mb-2" *ngIf="gridwidth == 0">
        <div class="nodata-wrap col-sm-12 mb-2">
            <div class="nodata-design font-size">No Data Found</div>
        </div>
        <div *ngIf="uploadbutton==false" class="align-center">
            <button type="button" (click)="UploadMethod()" mat-raised-button class="admin-btn-success mr-4"
                [disabled]="hideuploadBtn">Upload</button>
            <button (click)="copyOrgData()" mat-raised-button class="admin-btn-success" title="Copy from other entity"
                [disabled]="hideCopyToOrgBtn">Copy</button>
        </div>
        <div *ngIf="uploadbutton == true && OrgLevelDisplay" class="align-center">
            <button type="button" (click)="UploadMethod()" mat-raised-button class="admin-btn-success mr-4"
                [disabled]="hideuploadBtn">Upload</button>
            <button (click)="copyOrgData()" mat-raised-button class="admin-btn-success" title="Copy from other entity"
                [disabled]="hideCopyToOrgBtn">Copy</button>
        </div>
    </div>
</div>

<div class="modal-header admin-model-header mb-2 mt-3" *ngIf="noRole">
    <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>User Level Diagnosis Templates</strong>
    </h3>
</div>

<div class="container create-btn col-sm-12 align-center mt-4" *ngIf="noRole">
    <div class="col-sm-10 nodata-wrap">
        <div class="nodata-design">Eligible role not found for user</div>
    </div>
</div>


<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 *ngIf="!userCardFlag" class="modal-title w-100 admin-model-header-txt main-title">
            <strong *ngIf="OrgLevelDisplay">Copy Group Level Diagnosis Templates</strong>
            <strong *ngIf="!OrgLevelDisplay">Copy User Level Diagnosis Templates</strong>
        </h3>
        <h3 *ngIf="userCardFlag" class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Copy User Level Diagnosis Templates</strong>
        </h3>
    </div>
    <form [formGroup]="copycollectionMethodForm" (keydown.enter)="$event.preventDefault()" class="copy-collection">
        <div class="row col-sm-12 p-0 m-0">
            <div class="row col-sm-12 mt-2 m-0">
                <mat-form-field class="col-sm-2 example-additional-selection" appearance="outline">
                    <mat-label>Deployment</mat-label>
                    <mat-select type="text" disableOptionCentering matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                            (onSelectionChange)="onChangeDeployment($event,i)">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                    <mat-label class="mt-2">Group </mat-label>
                    <button matSuffix mat-icon-button ml-5 mt-2 (click)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
                        [disabled]="disableOrgSearch" #searchbar
                        (click)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)"
                        (keyup)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization); trimFieldValue('frmOrganization')"
                        [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align"
                                *ngIf="show.organizationname.toLowerCase().includes(searchInput.toLowerCase()) || show.organizationid.toString().includes(searchInput.toString())"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                                value="{{ show.organizationname }}"
                                title="{{ show.organizationname }} ({{show.organizationid}})">
                                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                    <button *ngIf="copycollectionMethodForm.value.frmOrganization" matSuffix mat-icon-button
                        aria-label="Clear" title="Reset Search" (click)="clearOrganization()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <!-- <div -->
                <div *ngIf="(this.templateData.cardtype.toLowerCase() == 'users') || (this.templateData.cardtype.toLowerCase() == 'pathologist')"
                    class="col-sm-3">
                    <mat-form-field class="col-sm-12 example-additional-selection" appearance="outline">
                        <mat-label>Casetype<span class="error-msg">*</span></mat-label>
                        <input type="text" matInput formControlName="frmcasetype" [(ngModel)]="searchCaseInput"
                            #searchuserbar (keyup)="fetchCaseSeries(searchCaseInput); trimCasetypeFieldValue('frmcasetype')" [matAutocomplete]="userauto"
                            placeholder="Search here ..." style="width: 95%;">
                        <mat-autocomplete #userauto="matAutocomplete">
                            <div *ngFor="let cases of searchCaselist">
                                <mat-option class="mat-opt-align" value="{{cases}}" title="{{ cases }}" (onSelectionChange)="selectedCopyCase($event, cases)">
                                    <span>{{ cases }}</span>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                        <button matSuffix mat-icon-button ml-5 (click)="fetchCaseSeries(searchCaseInput)">
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <!-- </div> -->


                <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                    <mat-label>User</mat-label>
                    <button matSuffix mat-icon-button ml-5 (click)="fetchUserSeries(searchUserInput)">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <input type="text" matInput formControlName="frmUser" [(ngModel)]="searchUserInput" #searchuserbar
                        (keyup)="fetchUserSeries(searchUserInput); trimUserFieldValue('frmUser')" [matAutocomplete]="userauto"
                        placeholder="Search here ..." style="width: 95%;">
                    <mat-autocomplete #userauto="matAutocomplete">
                        <div *ngFor="let user of searchuserlist">
                            <mat-option class="mat-opt-align"
                                *ngIf="user.username.toLowerCase().includes(searchUserInput.toLowerCase())"
                                (onSelectionChange)="selectedCopyDataUser($event, user)" value="{{ user.username }}"
                                title="{{ user.username }} ({{user.rolename}})">
                                <span>{{ user.username }} ({{user.rolename}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                    <!-- <button *ngIf="copycollectionMethodForm.value.frmUser" matSuffix mat-icon-button aria-label="Clear"
                        title="Reset Search" (click)="clearUser()">
                        <mat-icon>close</mat-icon>
                    </button> -->
                </mat-form-field>

            </div>
        </div>
        <div class="row align-center mt-3">
            <div *ngIf="(!backBtn && !postUpload) || (backBtn)" class="dml-btn">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshBackcopy()">
                    Back
                </button>
            </div>
            <div class="dml-btn">
                <button mat-raised-button class="admin-btn-success"
                    [disabled]="disableGetDataBtn() || (!backBtn && postUpload)" (click)="getDataToCopy()">Get
                    Data</button>
            </div>
        </div>
    </form>
    <div *ngIf="gridWidth > 0">
        <wj-flex-grid #flexgrid (initialized)="initializeGrid(flexgrid)" [isReadOnly]="true"
            [itemsSource]="copyGridData" style="margin-top: 17px;" [frozenColumns]="2"
            (formatItem)="formateItem(flexgrid, $event)">

            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column *ngIf="!userCardFlag" [binding]="columnname?.toString().toLowerCase()"
                    [header]="columnname"
                    [visible]="!(columnname == 'diagnosis') && !(columnname == 'Status') && !(columnname == 'siteid') && !(columnname.toLowerCase()=='organizationid') && !(columnname.toLowerCase()=='orgdiagtemplateid') && !(columnname.toLowerCase()=='casetype') &&!(columnname.toLowerCase()=='groupname')"
                    [allowResizing]="true" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
                <wj-flex-grid-column *ngIf="userCardFlag" [binding]="columnname?.toString().toLowerCase()"
                    [header]="columnname"
                    [visible]="!(columnname == 'diagnosis') && !(columnname == 'Status') && !(columnname.toLowerCase()=='organizationid') && !(columnname.toLowerCase()=='orgdiagtemplateid') &&!(columnname.toLowerCase()=='groupname') "
                    [allowResizing]="true" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>

            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span [ngStyle]="{color: cell.item.status=='Success' ? 'green' : 'red'}">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <!-- Notes -->
            <!-- <wj-flex-grid-column [width]="320" [header]="'Notes'" [binding]="notes" [allowResizing]="true"
          [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
              <span
                  [ngStyle]="{color: (cell.item.notes == 'Valid') ? 'green' : cell.item.notes.toString().includes('already exists') ? 'red' : '#ff9800' }">{{cell.item.notes}}
              </span>
          </ng-template>
      </wj-flex-grid-column> -->
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <br />
        <div *ngIf="!backBtn && !postUpload" class="row pl-3 mt-2 mb-2 ml-5 align-center">
            <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0" ng-init='checkStatus=false'
                [checked]='checkStatus' ng-model='checkStatus' (change)='checkValue($event)' class="border-style">
            <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
        </div>
        <div class="row align-center mt-4 mb-3">
            <div *ngIf="!backBtn" class="dml-btn">
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                    (click)="ExportCopyStatus(flexgrid)">Download</button>
                <button *ngIf="userCardFlag && postUpload" mat-raised-button class="admin-btn-success mr-4"
                    (click)="refreshBackcopy()">
                    Finish
                </button>
                <button *ngIf="!userCardFlag && postUpload" mat-raised-button class="admin-btn-success mr-4"
                    (click)="refreshBackcopy()">
                    Finish
                </button>
            </div>
            <div *ngIf="!postUpload" class="dml-btn">
                <button mat-raised-button class="admin-btn-success" [disabled]="disableApprovebtn()" 
                    (click)="uploadCopyData(flexgrid)">Approve</button>
            </div>
        </div>
    </div>
    <div class="col-sm-12 container create-btn align-center mt-4" *ngIf="gridWidth == 0">
        <div class="col-sm-12 nodata-wrap">
            <div class="nodata-design font-size">No Data Found</div>
        </div>
    </div>
</div>

<div *ngIf="detailsDisplay" class="col-sm-12">
    <app-bulk-upload-diagnosis-template [templateData]="templateData" [inData]="inData" [userRoles]="userRoles" [hideupload]="uploadClicked">
    </app-bulk-upload-diagnosis-template>
    <!-- <app-bulk-upload-diagnosis-template-usercardlevel [templateData]="templateData" [inData]="uploaduserid" [hideupload]="uploadClicked"></app-bulk-upload-diagnosis-template-usercardlevel> -->
</div>

<!-- warning popup -->
<div *ngIf="invalidCasetypeFlag" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header warning-model-header">
                    <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                    <span type="button" id="button_size" data-dismiss="modal" (click)="closeCasetypeModel()"
                        class="mr-2">
                        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close"
                            class="material-icons md-24 cursor" title="Close">
                    </span>
                </div>
                <div class="modal-body p-2 msg-wrap">
                    <div class="row col-sm-12 body-message">
                        <p> The Diagnosis Templates
                            cannot be copied as the casetype is not enabled in destination Group.
                            Please enable the casetype and then try copying<br>Failed for Casetype :
                            {{repeatedNames}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button mat-raised-button class="admin-btn-success" (click)="closeCasetypeModel()"
                        type="button">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>
